import React, { Component } from 'react';
import logo_delete from '../img/delete.jpg';
import '../css/Devis.css';
import '../css/List_users.css';
import { connect } from "react-redux";
import back from '../img/back.svg';
import next from '../img/next.svg';
import Commande from './Commande';
import Update_logo from './Update_logo';
import close from '../img/close.png';
import logo_oeil from '../img/eye_black.png';
import { findonelogo, deletelogo } from '../api/UserFunctions';
import { changeSuiviP, changeAllLogos, changeLogoAdm, changeChoixAdm, changeTitle, changeStyleProfil, changeColorC, changeColorD, changeBoolP, changeStyleD, changeStyleC, changeCT, changeDT} from "../actions/index";


function mapDispatchToProps(dispatch) {
    return {
      changeTitle: title_header => dispatch(changeTitle(title_header)),
      changeSuiviP: suivi_page => dispatch(changeSuiviP(suivi_page)),
      changeStyleProfil: style_profil => dispatch(changeStyleProfil(style_profil)),
      changeStyleD: style_d => dispatch(changeStyleD(style_d)),
      changeStyleC: style_c => dispatch(changeStyleC(style_c)),
      changeDT: dt => dispatch(changeDT(dt)),
      changeCT: ct => dispatch(changeCT(ct)),
      changeBoolP: boolp => dispatch(changeBoolP(boolp)),
      changeColorD: colord => dispatch(changeColorD(colord)),
      changeColorC: colorc => dispatch(changeColorC(colorc)),
      changeLogoAdm: logo_adm => dispatch(changeLogoAdm(logo_adm)),
      changeChoixAdm: choix_adm => dispatch(changeChoixAdm(choix_adm)),
      changeAllLogos: all_logos => dispatch(changeAllLogos(all_logos))
    };
}

const mapStateToProps = state => {
  return { all_logos:state.all_logos,commande_user:state.commande_user, profile_user: state.profile_user   };
};



class List_logo extends Component {

 constructor(props){
    super(props);
    this.state = {
      deb: 0,
      nb_devis:5,
      search:false,
      find:'',
      style_tr:{backgroundColor:"#fafafa"},
      test:false
    }
    this.handlePageS = this.handlePageS.bind(this);
     this.handleUser = this.handleUser.bind(this);
    this.handlePageP = this.handlePageP.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleSuppr = this.handleSuppr.bind(this);
  }


  onChange(e) {
    this.setState({ [e.target.name]: e.target.value })
    this.setState({search:true});
  }




  formatDate(date){
    if(typeof(date)==='number'){
      date=date.toString();
    }
    console.log("test : "+date);
    if(date.length > 5){
        let year = date.substring(0,4);
        let month = date.substring(4,6);
        let day = date.substring(6,8);

    return day+"/"+month+"/"+year;
    }

    return '';
    
   
  }

  renderTable(nb,nb2){
    let test = false;
    let style_tr = {backgroundColor:"lightgreen"};
    if(this.state.search){
      return this.renderTable2(nb,nb2);          
    }
    else{
      return this.props.all_logos.map((all,index) => {
        const{id, etps} = all;
        if(index<nb && index>=nb2){ 
            return (
              <div key={id}><p  id="p_all_logos" onClick={(event) => this.handleUser(id)}>{"Entreprise : "+etps}</p><img onClick={(event) => this.handleDelete(id)} id="sp" src={logo_delete} alt="logo de suppression"/></div>
              
            )
          }
        return null;
    });

    }
    
  }

  handleC(data){
    this.props.changeCT(true);
    this.props.changeDT(false);
    this.props.changeTitle( 'Commandes' );
    this.props.changeSuiviP( <Commande  key={"commande"} dataD={data} joinD="true"/> );
    this.props.changeStyleProfil({display:"none"});
    this.props.changeStyleC({backgroundColor:"#f5f6f8"});
    this.props.changeStyleD({backgroundColor:"#f5f6f8"});
    this.props.changeBoolP(false);
    this.props.changeColorC(false);
    this.props.changeColorD(true);
  }

  handleUser(data){
    findonelogo(data).then(res => {
      this.props.changeLogoAdm(res);
      this.props.changeChoixAdm( <Update_logo  admlogo={res} key={"update_logo"} /> );
      });

  }

  handleDelete(data){
    deletelogo(data).then(res => {
       let newArray = [];
       this.props.all_logos.forEach((userdata,index) => {
       if(userdata.id === data)
       {
        
        }
        else{
          newArray.push(userdata);
        }
        });
        this.props.changeAllLogos(newArray);
      });

  }

  handleSuppr(event){

    this.setState({find:''});
   
  }

  renderTable2(nb,nb2){
    let test = false;
    let mapping = [];
    let i = 0;
    let style_tr = {backgroundColor:"lightgreen"};
    this.props.all_logos.forEach((all,index) => {
      const{id, etps} = all;
      let tot = etps
      if(tot.toUpperCase().indexOf(this.state.find.toUpperCase()) !== -1  && id)
      {
        mapping[i] = all;
        i++;
      }
    });
    while((nb-mapping.length)>5){
      nb-=5;
      nb2-=5;
    }
    return mapping.map((all,index) => {
      const{id, etps} = all;
      let tot = etps
      if(tot.toUpperCase().indexOf(this.state.find.toUpperCase()) !== -1 )
      {
            if(index<nb && index>=nb2){ 
              return (
                <div key={id}><p  id="p_all_logos" onClick={(event) => this.handleUser(id)}>{"Entreprise : "+etps}</p><img onClick={(event) => this.handleDelete(id)} id="sp" src={logo_delete} alt="logo de suppression"/></div>
              
            )
            }
        }
        return null;
      
      return null;
    });
  }
 
 handlePageS(){
    if(this.state.nb_devis < this.props.all_logos.length){
      let nb_deb = this.state.deb;
      let nb_fin = this.state.nb_devis;
      this.setState({deb:nb_deb+5});
      this.setState({nb_devis:nb_fin+5});
    }
    
  }

  handlePageP(){

    if(this.state.deb >= 5){
      let nb_deb = this.state.deb;
      let nb_fin = this.state.nb_devis;
      this.setState({deb:nb_deb-5});
      this.setState({nb_devis:nb_fin-5});
    }
   
  }

  render() {
    
    return (
    <div className="devis">
    <input
        id="search1"
        type="text"
        placeholder="Recherche"
        name="find"
        value={this.state.find}
        onChange={this.onChange}
      /> {this.state.find.localeCompare("")==0?null:<img id="croix_cmd3" onClick={(event) => this.handleSuppr(event)} src={close} alt="croix"/>}
        {this.renderTable(this.state.nb_devis,this.state.deb)}
      <div id="page_d">
        <img id="pagePD2" onClick={(event) => this.handlePageP(event)} src={back} alt="Logo back"/>
        <img id="pageSD2" onClick={(event) => this.handlePageS(event)} src={next} alt="Logo next"/>
      </div>
    </div>
      );
    }
  }
export default connect(mapStateToProps, mapDispatchToProps)(List_logo);
