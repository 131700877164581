import React, { Component } from 'react';
import '../css/profil.css';
import '../css/Login.css';
import '../css/Create_user.css';
import { connect } from "react-redux";
import List_users from './List_users';
import logo_oeil from '../img/eye.png';
import logo_no_oeil from '../img/no-visibility.png';
import { register, sendImg, update_user} from '../api/UserFunctions';

import { changeAllUsers, changeChoixAdm } from "../actions/index";
function mapDispatchToProps(dispatch) {
    return {
      changeAllUsers:all_users => dispatch(changeAllUsers(all_users)),
      changeChoixAdm: choix_adm => dispatch(changeChoixAdm(choix_adm))
    };
}

const bcrypt = require('bcryptjs');


const mapStateToProps = state => {
  return { profil_adm: state.profil_adm, all_users: state.all_users };
};

class Update_user extends Component {


  constructor(props){
    super(props);
    this.state = {
      value_res:'',
      test1:true,
      test2:true,
      first_name: this.props.admprofil[0].first_name,
      last_name: this.props.admprofil[0].last_name,
      email: this.props.admprofil[0].email,
      password: '',
      groupe: this.props.admprofil[0].groupe,
      num_client: this.props.admprofil[0].num_client,
      tel: this.props.admprofil[0].tel,
      etps: this.props.admprofil[0].etps,
      site_web: this.props.admprofil[0].site_web,
      adr: this.props.admprofil[0].adr,
      postal: this.props.admprofil[0].postal,
      ville: this.props.admprofil[0].ville,
      pays: this.props.admprofil[0].pays,
      pays_num: this.props.admprofil[0].pays_num,
      contract_date: this.props.admprofil[0].contract_date,
      user_detail: this.props.admprofil[0].user_detail==true?"oui":"non",
      selectedFile: null
    }
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.handlePass1 = this.handlePass1.bind(this);
    this.handlePass1P = this.handlePass1P.bind(this);
    this.handlePass2 = this.handlePass2.bind(this);
    this.handlePass2P = this.handlePass2P.bind(this);
    this.handleAnnul = this.handleAnnul.bind(this);
  }


  onSubmit(e) {
      e.preventDefault();

      if(this.state.email.localeCompare("")!==0){

          var user = {};

          var contract = this.state.contract_date;
          if(contract !== null){
            if(contract.toString().localeCompare("")===0){
              contract = null;
            }
          }
         

              if(this.state.password.localeCompare("")==0){
                user = {
                  id:this.props.admprofil[0].id,
                  first_name: this.state.first_name,
                  last_name: this.state.last_name,
                  email: this.state.email,
                  groupe: this.state.groupe,
                  num_client: this.state.num_client,
                  tel: this.state.tel,
                  etps: this.state.etps,
                  site_web: this.state.site_web,
                  adr: this.state.adr,
                  postal: this.state.postal,
                  ville: this.state.ville,
                  pays: this.state.pays,
                  pays_num: this.state.pays_num,
                  contract_date: contract,
                  user_detail: this.state.user_detail
                }
              }
              else{
                user = {
                  id:this.props.admprofil[0].id,
                  first_name: this.state.first_name,
                  last_name: this.state.last_name,
                  email: this.state.email,
                  password: this.state.password,
                  groupe: this.state.groupe,
                  num_client: this.state.num_client,
                  tel: this.state.tel,
                  etps: this.state.etps,
                  site_web: this.state.site_web,
                  adr: this.state.adr,
                  postal: this.state.postal,
                  ville: this.state.ville,
                  pays: this.state.pays,
                  pays_num: this.state.pays_num,
                  contract_date: contract,
                  user_detail: this.state.user_detail
                }
              }

              
              update_user(user).then(res => {
                console.log("update : "+this.props.all_users);
                let newArray = [];
                this.props.all_users.forEach((userdata,index) => {
                  if(this.props.admprofil[0].id === userdata.id)
                  {
                    newArray.push(user);
                  }
                  else{
                    newArray.push(userdata);
                  }
                });
                this.props.changeAllUsers(newArray);
                this.props.changeChoixAdm(<List_users  key={"list_user"} />);
              });

      }
      else{
        this.setState({value_res:"L'email ne peut pas être vide"});
      }
    }


  onChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  handlePass1(e){
    this.setState({pass1:'text'});
    this.setState({test1:false});
  }

  handlePass1P(e){
    this.setState({pass1:'password'});
    this.setState({test1:true});
  }

   handlePass2(e){
    this.setState({pass2:'text'});
    this.setState({test2:false});
  }

  handlePass2P(e){
    this.setState({pass2:'password'});
    this.setState({test2:true});
  }

  handleAnnul(e){
    this.props.changeChoixAdm(<List_users  key={"list_user"} />);
  }

  onFileChange = event => { 
     
      // Update the state 
      this.setState({ selectedFile: event.target.files[0] }); 
     
    }; 



  render() {
    return (

    	<div className="create_user">
        <div id="block1">
          <div id='img_p'>
          <form className="form_profile" id="profile2" onSubmit={this.onSubmit}>
                <p className="p_info">Prénom&nbsp;&nbsp;&nbsp;</p>
                <input
                  id="form_profile_mdp22"
                  type="text"
                  name="first_name"
                  placeholder="Prénom"
                  value={this.state.first_name}
                  onChange={this.onChange}
                  />
                  <br/>
                <p className="p_info">Nom de famille&nbsp;&nbsp;&nbsp;</p>
                <input
                  id="form_profile_mdp22"
                  type="text"
                  name="last_name"
                  placeholder="Nom de famille"
                  value={this.state.last_name}
                  onChange={this.onChange}
                />
                <br/>
                <p className="p_info">Email&nbsp;&nbsp;&nbsp;</p>
                  <input
                    id="form_profile_mdp22"
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={this.state.email}
                    onChange={this.onChange}
                  />
                <br/>
                <p className="p_info">Mot de passe&nbsp;&nbsp;&nbsp;</p>
                <input
                  id="form_profile_mdp22"
                  type="password"
                  name="password"
                  placeholder="Mot de passe"
                  value={this.state.password}
                  onChange={this.onChange}
                />
                <br/>
                <p className="p_info">Groupe&nbsp;&nbsp;&nbsp;</p>
                <input
                  id="form_profile_mdp22"
                  type="text"
                  name="groupe"
                  placeholder="Groupe"
                  value={this.state.groupe}
                  onChange={this.onChange}
                />
                <br/>
                <p className="p_info">Numéro client&nbsp;&nbsp;&nbsp;</p>
                <input
                  id="form_profile_mdp22"
                  type="text"
                  name="num_client"
                  placeholder="Numéro client"
                  value={this.state.num_client}
                  onChange={this.onChange}
                />
                <br/>
                <p className="p_info">Téléphone&nbsp;&nbsp;&nbsp;</p>
                <input
                  id="form_profile_mdp22"
                  type="text"
                  name="tel"
                  placeholder="Téléphone"
                  value={this.state.tel}
                  onChange={this.onChange}
                />
                <br/>
                <p className="p_info">Entreprise&nbsp;&nbsp;&nbsp;</p>
                <input
                  id="form_profile_mdp22"
                  type="text"
                  name="etps"
                  placeholder="Entreprise"
                  value={this.state.etps}
                  onChange={this.onChange}
                />
                <br/>
                <p className="p_info">Site web de l'entreprise&nbsp;&nbsp;&nbsp;</p>
                <input
                  id="form_profile_mdp22"
                  type="text"
                  name="site_web"
                  placeholder="Site web de l'entreprise"
                  value={this.state.site_web}
                  onChange={this.onChange}
                />
                <br/>
                <p className="p_info">Adresse de l'entreprise&nbsp;&nbsp;&nbsp;</p>
                <input
                  id="form_profile_mdp22"
                  type="text"
                  name="adr"
                  placeholder="Adresse de l'entreprise"
                  value={this.state.adr}
                  onChange={this.onChange}
                />
                <br/>
                 <p className="p_info">Code postal de l'entreprise&nbsp;&nbsp;&nbsp;</p>
                <input
                  id="form_profile_mdp22"
                  type="text"
                  name="postal"
                  placeholder="Code postal de l'entreprise"
                  value={this.state.postal}
                  onChange={this.onChange}
                />
                <br/>
                 <p className="p_info">Ville de l'entreprise&nbsp;&nbsp;&nbsp;</p>
                <input
                  id="form_profile_mdp22"
                  type="text"
                  name="ville"
                  placeholder="Ville de l'entreprise"
                  value={this.state.ville}
                  onChange={this.onChange}
                />
                <br/>
                <p className="p_info">Pays de l'entreprise&nbsp;&nbsp;&nbsp;</p>
                <input
                  id="form_profile_mdp22"
                  type="text"
                  name="pays"
                  placeholder="Pays de l'entreprise"
                  value={this.state.pays}
                  onChange={this.onChange}
                />
                <br/>
                <p className="p_info">Pays de l'utilisateur&nbsp;&nbsp;&nbsp;</p>
                <input
                  id="form_profile_mdp22"
                  type="text"
                  name="pays_num"
                  placeholder="Pays de l'utilisateur"
                  value={this.state.pays_num}
                  onChange={this.onChange}
                />
                <br/>
                <p className="p_info">Date du contrat&nbsp;&nbsp;&nbsp;</p>
                <input
                  id="form_profile_mdp22"
                  type="date"
                  name="contract_date"
                  value={this.state.contract_date}
                  onChange={this.onChange}
                />
                <br />
                <p className="p_info">Accès au détails&nbsp;&nbsp;&nbsp;</p>
                <select name="user_detail" value={this.state.user_detail}  onChange={this.onChange}>
                  <option value="non">Non</option>
                  <option value="oui">Oui</option>
                </select>
                <br />
                <button id="form_button" type="submit">Modifier</button><button onClick={(event) => this.handleAnnul(event)} id="form_button" >Annuler</button>
                <p>{this.state.value_res.toString()}</p>
              </form>

              
          </div>
        </div>
		  </div>
      );
    }
  }
export default connect(mapStateToProps, mapDispatchToProps)(Update_user);


