import React, { Component } from 'react';
import '../css/Suivi.css';
import LEFT_SUIVI from './Left_suivi';
import { connect } from "react-redux";

const mapStateToProps = state => {
  return { suivi_page: state.suivi_page };
};

class Misajour extends Component {

  constructor(props){
    super(props);
    this.state = {
      m1:{display:"none"},
      m2:{display:"none"},
      m3:{display:"none"},
      m4:{display:"none"},
      m5:{display:"none"},
      m6:{display:"none"},
      m7:{display:"none"},
      m8:{display:"block"},
    }
    this.onm1 = this.onm1.bind(this);
    this.onm2 = this.onm2.bind(this);
    this.onm3 = this.onm3.bind(this);
    this.onm4 = this.onm4.bind(this);
    this.onm5 = this.onm5.bind(this);
    this.onm6 = this.onm6.bind(this);
    this.onm7 = this.onm7.bind(this);
    this.onm8 = this.onm8.bind(this);
  }

  onm1(){
    this.setState({m1:{display:"block"}});
    this.setState({m2:{display:"none"}});
    this.setState({m3:{display:"none"}});
    this.setState({m4:{display:"none"}});
    this.setState({m5:{display:"none"}});
    this.setState({m6:{display:"none"}});
    this.setState({m7:{display:"none"}});
    this.setState({m8:{display:"none"}});
  }

  onm2(){
    this.setState({m2:{display:"block"}});
    this.setState({m1:{display:"none"}});
    this.setState({m3:{display:"none"}});
    this.setState({m4:{display:"none"}});
    this.setState({m5:{display:"none"}});
    this.setState({m6:{display:"none"}});
    this.setState({m7:{display:"none"}});
    this.setState({m8:{display:"none"}});
  }

  onm3(){
    this.setState({m1:{display:"none"}});
    this.setState({m2:{display:"none"}});
    this.setState({m3:{display:"block"}});
    this.setState({m4:{display:"none"}});
    this.setState({m5:{display:"none"}});
    this.setState({m6:{display:"none"}});
    this.setState({m7:{display:"none"}});
    this.setState({m8:{display:"none"}});
  }

  onm4(){
    this.setState({m1:{display:"none"}});
    this.setState({m2:{display:"none"}});
    this.setState({m3:{display:"none"}});
    this.setState({m4:{display:"block"}});
    this.setState({m5:{display:"none"}});
    this.setState({m6:{display:"none"}});
    this.setState({m7:{display:"none"}});
    this.setState({m8:{display:"none"}});
  }

  onm5(){
    this.setState({m1:{display:"none"}});
    this.setState({m2:{display:"none"}});
    this.setState({m3:{display:"none"}});
    this.setState({m4:{display:"none"}});
    this.setState({m5:{display:"block"}});
    this.setState({m6:{display:"none"}});
    this.setState({m7:{display:"none"}});
    this.setState({m8:{display:"none"}});
  }

  onm6(){
    this.setState({m1:{display:"none"}});
    this.setState({m2:{display:"none"}});
    this.setState({m3:{display:"none"}});
    this.setState({m4:{display:"none"}});
    this.setState({m5:{display:"none"}});
    this.setState({m6:{display:"block"}});
    this.setState({m7:{display:"none"}});
    this.setState({m8:{display:"none"}});
  }

  onm7(){
    this.setState({m1:{display:"none"}});
    this.setState({m2:{display:"none"}});
    this.setState({m3:{display:"none"}});
    this.setState({m4:{display:"none"}});
    this.setState({m5:{display:"none"}});
    this.setState({m6:{display:"none"}});
    this.setState({m7:{display:"block"}});
    this.setState({m8:{display:"none"}});
  }

  onm8(){
    this.setState({m1:{display:"none"}});
    this.setState({m2:{display:"none"}});
    this.setState({m3:{display:"none"}});
    this.setState({m4:{display:"none"}});
    this.setState({m5:{display:"none"}});
    this.setState({m6:{display:"none"}});
    this.setState({m7:{display:"none"}});
    this.setState({m8:{display:"block"}});
  }
  


  render() {
    return (
    	<div className="maj">
            <h1 className="m" onClick={(event) => this.onm1()} style={{fontSize:"1.5em"}}>MAJ mineure 1.11.21.1</h1>
            <ul style={this.state.m1}>
              <li>Améliorations ergonomiques</li>
              <li>Mise à jour de la plate-forme admin</li>
              <li>Gestion des mots de passe oubliés</li>
            </ul>
            <br/>
            <h1 className="m" onClick={(event) => this.onm2()} style={{fontSize:"1.5em"}}>MAJ mineure 1.10.21.1</h1>
            <ul style={this.state.m2}>
              <li>Gestion de plusieurs marché par compte client</li>
              <li>Optimisation ordre croissant d'affichage</li>
              <li>Modification de l'entête de la section Factures</li>
            </ul>
            <br/>
            <h1 className="m" onClick={(event) => this.onm3()} style={{fontSize:"1.5em"}}>MAJ mineure 1.04.22.1</h1>
            <ul style={this.state.m3}>
              <li>Prise en compte des références commandes clients dans le module de recherche commande</li>
            </ul>
            <h1 className="m" onClick={(event) => this.onm4()} style={{fontSize:"1.5em"}}>MAJ mineure 1.06.22.1</h1>
            <ul style={this.state.m4}>
              <li>Optimisation de la sécurité des mots de passe</li>
            </ul>
            <h1 className="m" onClick={(event) => this.onm5()} style={{fontSize:"1.5em"}}>MAJ majeure 1.07.22.1</h1>
            <ul style={this.state.m5}>
              <li>Optimisation des temps de chargements</li>
            </ul>
            <h1 className="m" onClick={(event) => this.onm6()} style={{fontSize:"1.5em"}}>MAJ mineure 1.08.22.1</h1>
            <ul style={this.state.m6}>
              <li>Reporting non accessible (en cours de modification)</li>
              <li>Délais prévisonnels en cours de traitement</li>
            </ul>
            <h1 className="m" onClick={(event) => this.onm7()} style={{fontSize:"1.5em"}}>MAJ mineure 1.09.22.1</h1>
            <ul style={this.state.m7}>
              <li>Mise à disposition des procès-verbaux en cas d'intervention</li>
            </ul>
            <h1 className="m" onClick={(event) => this.onm8()} style={{fontSize:"1.5em"}}>MAJ mineure 1.03.23.1</h1>
            <ul style={this.state.m8}>
              <li>Ajout des dates de livraisons sur ligne produit</li>
            </ul>
		  </div>
      );
    }
  }
export default connect(mapStateToProps)(Misajour);


