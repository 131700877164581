import { CHANGE_STATUS_SUIVI, CHANGE_PAGE, CHANGE_ISREVERSE, CHANGE_DEB, CHANGE_NB_DEVIS, CHANGE_FIND, CHANGE_STYLE_RETOUR, CHANGE_DETAIL,  CHANGE_PROFIL_ADM, CHANGE_TAB_DATA1, CHANGE_TAB_DATA2, CHANGE_TAB_DATA3, CHANGE_TAB_GROUPE, CHANGE_LOGO_ADM,  CHANGE_ALL_USERS,  CHANGE_ALL_LOGOS, CHANGE_BOOLP, CHANGE_CHOIXADM, CHANGE_IMGGET,CHANGE_IMG, CHANGE_FT, CHANGE_RT, CHANGE_ADM_PAGE, CHANGE_STYLEF, CHANGE_STYLER, CHANGE_COLORF, CHANGE_COLORR, CHANGE_COLORC, CHANGE_COLORD, CHANGE_PRESTA, CHANGE_FACTURE, CHANGE_CHARGE, CHANGE_SUIVI_PAGE, CHANGE_CT, CHANGE_DT, CHANGE_STYLEPROFIL, CHANGE_TITLE, CHANGE_STYLED, CHANGE_STYLEP, CHANGE_STYLEC, CHANGE_PROFILE, CHANGE_COMMANDE, CHANGE_DEVIS, CHANGE_AUTH} from "./action-types";


export function changeProfilAdm(payload) {
  return { type: CHANGE_PROFIL_ADM, payload }
};

export function changePage(payload) {
  return { type: CHANGE_PAGE, payload }
};

export function changeIsReverse(payload) {
  return { type: CHANGE_ISREVERSE, payload }
};

export function changeDeb(payload) {
  return { type: CHANGE_DEB, payload }
};

export function changeNbDevis(payload) {
  return { type: CHANGE_NB_DEVIS, payload }
};

export function changeFind(payload) {
  return { type: CHANGE_FIND, payload }
};

export function changeStyleRetour(payload) {
  return { type: CHANGE_STYLE_RETOUR, payload }
};

export function changeDetail(payload) {
  return { type: CHANGE_DETAIL, payload }
};

export function changeTabData1(payload) {
  return { type: CHANGE_TAB_DATA1, payload }
};

export function changeTabData2(payload) {
  return { type: CHANGE_TAB_DATA2, payload }
};

export function changeTabData3(payload) {
  return { type: CHANGE_TAB_DATA3, payload }
};

export function changeTabGroupe(payload) {
  return { type: CHANGE_TAB_GROUPE, payload }
};


export function changeLogoAdm(payload) {
  return { type: CHANGE_LOGO_ADM, payload }
};

export function changeAllUsers(payload) {
  return { type: CHANGE_ALL_USERS, payload }
};

export function changeAllLogos(payload) {
  return { type: CHANGE_ALL_LOGOS, payload }
};

export function changeAdmPage(payload) {
  return { type: CHANGE_ADM_PAGE, payload }
};

export function changeChoixAdm(payload) {
  return { type: CHANGE_CHOIXADM, payload }
};


export function changeImgGet(payload) {
  return { type: CHANGE_IMGGET, payload }
};


export function changeStatus(payload) {
  return { type: CHANGE_STATUS_SUIVI, payload }
};

export function changeColorD(payload) {
  return { type: CHANGE_COLORD, payload }
};

export function changeColorR(payload) {
  return { type: CHANGE_COLORR, payload }
};

export function changeColorF(payload) {
  return { type: CHANGE_COLORF, payload }
};

export function changeColorC(payload) {
  return { type: CHANGE_COLORC, payload }
};

export function changeBoolP(payload) {
  return { type: CHANGE_BOOLP, payload }
};

export function changePresta(payload) {
  return { type: CHANGE_PRESTA, payload }
};

export function changeImg(payload) {
  return { type: CHANGE_IMG, payload }
};


export function changeCharge(payload) {
  return { type: CHANGE_CHARGE, payload }
};


export function changeCT(payload) {
  return { type: CHANGE_CT, payload }
};

export function changeRT(payload) {
  return { type: CHANGE_RT, payload }
};

export function changeDT(payload) {
  return { type: CHANGE_DT, payload }
};

export function changeFT(payload) {
  return { type: CHANGE_FT, payload }
};

export function changeStyleProfil(payload) {
  return { type: CHANGE_STYLEPROFIL, payload }
};

export function changeSuiviP(payload) {
  return { type: CHANGE_SUIVI_PAGE, payload }
};

export function changeTitle(payload) {
  return { type: CHANGE_TITLE, payload }
};

export function changeStyleD(payload) {
  return { type: CHANGE_STYLED, payload }
};

export function changeStyleP(payload) {
  return { type: CHANGE_STYLEP, payload }
};

export function changeStyleF(payload) {
  return { type: CHANGE_STYLEF, payload }
};


export function changeStyleC(payload) {
  return { type: CHANGE_STYLEC, payload }
};

export function changeStyleR(payload) {
  return { type: CHANGE_STYLER, payload }
};

export function changeProfile(payload) {
  return { type: CHANGE_PROFILE, payload }
};

export function changeCommande(payload) {
  return { type: CHANGE_COMMANDE, payload }
};

export function changeDevis(payload) {
  return { type: CHANGE_DEVIS, payload }
};

export function changeFacture(payload) {
  return { type: CHANGE_FACTURE, payload }
};

export function changeAuth(payload) {
  return { type: CHANGE_AUTH, payload }
};