import React, {Component} from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import Connexion from '../components/Connexion';
import App from '../components/App';
import App_adm from '../components/App_adm';
import Forget from '../components/Forgot';
import PrivateRoute from './PrivateRoute';
import { connect } from "react-redux";


class Router extends Component {

  render() {
    return (
      <BrowserRouter>
        <div>
          <Route exact path="/" component={App} />
          <PrivateRoute exact path="/proviso_user" component={Connexion} authed={this.props.auth} />
          <Route exact path="/admin_cfi" component={App_adm} />
          <Route exact path="/forget/:id" component={Forget} />
        </div>
      </BrowserRouter>
    );
  }
}
const mapStateToProps = state => ({ auth: state.auth });
export default connect(mapStateToProps)(Router);
