import React, { Component } from 'react';
import Accueil from './Accueil';
import Header from './Header';
import Commande from './Commande';
import Chargement from './Chargement';
import Devis from './Devis';
import Suivi from './Suivi';
import { connect } from "react-redux";
import { profile, commande, devis, commande_groupe, devis_groupe, facture, facture_groupe, presta, imgList, commande_all, devis_all, facture_all } from '../api/UserFunctions';
import { changeProfile, changeTabGroupe, changeTabData1, changeTabData2, changeTabData3, changeCommande, changeDevis, changePresta, changeAuth, changeStatus, changeFacture, changeImg, changeImgGet } from "../actions/index";

var jwtDecode = require('jwt-decode');

function mapDispatchToProps(dispatch) {
  return {
    changeProfile: profile_user => dispatch(changeProfile(profile_user)),
    changeCommande: commande_user => dispatch(changeCommande(commande_user)),
    changeDevis: devis_user => dispatch(changeDevis(devis_user)),
    changeFacture: facture_user => dispatch(changeFacture(facture_user)),
    changeAuth: auth => dispatch(changeAuth(auth)),
    changeStatus: suivi_status => dispatch(changeStatus(suivi_status)),
    changePresta: presta_user => dispatch(changePresta(presta_user)),
    changeImg: img_list => dispatch(changeImg(img_list)),
    changeImgGet: img_get => dispatch(changeImgGet(img_get)),
    changeTabGroupe: tabGroupe => dispatch(changeTabGroupe(tabGroupe)),
    changeTabData1: tabData1 => dispatch(changeTabData1(tabData1)),
    changeTabData2: tabData2 => dispatch(changeTabData2(tabData2)),
    changeTabData3: tabData3 => dispatch(changeTabData3(tabData3))
  };
}

const mapStateToProps = state => {
  return { suivi_status: state.suivi_status, profile_user: state.profile_user, charge: state.charge };
};

class Connexion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginPage: [],
      loginSuivi: [],
      style_d: { backgroundColor: "#eeeeee" },
      style_c: { backgroundColor: "#eeeeee" },
      title: '',
      profile: false,
      devis: false,
      commande: false,
      facture: false,
      presta: false,
      img_list: false,
      img_get: false,
      tabGroupe: false,
      tabData1: false,
      tabData2: false,
      tabData3: false

    }

    this.handleDevis = this.handleDevis.bind(this);
    this.handleCommande = this.handleCommande.bind(this);
    this.chackLoginStatus = this.checkLoginStatus.bind(this);

  }


  checkLoginStatus() {

    if (jwtDecode(localStorage.getItem('usertoken')).exp < Date.now() / 1000) {
      localStorage.setItem('user', false);
      this.props.changeStatus(<Accueil key={"accueil"} />);
      window.location.href = process.env.REACT_APP_ID_PROVISO;
    }


  }

  handleDevis() {
    var uploadScreenSuivi = [];
    uploadScreenSuivi.push(<Devis component={Devis} appContext={this} key={"devis"} />);
    this.setState({ loginSuivi: uploadScreenSuivi });
    var uploadScreen = [];
    uploadScreen.push(<Suivi component={Suivi} appContext={this.props.appContext} key={"suivi"} loginSuivi={this.props.loginSuivi} style_d={this.props.style_d} style_c={this.props.style_c} />);
    this.setState({ loginPage: uploadScreen });
    this.setState({ title: "Devis" });


  }

  handleCommande() {
    var uploadScreen = [];
    uploadScreen.push(<Suivi component={Suivi} appContext={this.props.appContext} key={"suivi"} loginSuivi={this.state.loginSuivi} style_d={this.state.style_d} style_c={this.state.style_c} />);

    this.setState({ loginPage: uploadScreen });

    var uploadScreenSuivi = [];
    uploadScreenSuivi.push(<Commande component={Commande} appContext={this} key={"devis"} testF={this.testF} />);
    this.setState({ loginSuivi: uploadScreenSuivi });


  }


  componentDidMount() {
    var tabGroupe = ["TOUS"];
    var tabData1 = [];
    var tabData2 = [];
    var tabData3 = [];
    presta().then(res => {
      this.setState({ presta: res });
      this.props.changePresta(res);
    });
    imgList().then(res => {
      this.setState({ img_list: res });
      this.props.changeImg(res);
    });
    profile().then(res => {
      this.setState({ profile: res });
      this.props.changeProfile(res);
      if (res.groupe.length === 0) {

        devis(res.num_client).then(resdev => {
          if( typeof resdev !== "undefined"){
            console.log(resdev);
            var sortResdev = resdev.sort(function compare(b, a) {
              var string1;
              var string2;
              if (a.Date_Crea_Devis) {
                string1 = a.Date_Crea_Devis.toString();
              }
              else {
                string1 = "";
              }
              if (b.Date_Crea_Devis) {
                string2 = b.Date_Crea_Devis.toString();
              }
              else {
                string2 = "";
              }
  
              var dateA = new Date(parseInt(string1.substring(0, 4)), parseInt(string1.substring(4, 6)), parseInt(string1.substring(6, 8)));
              var dateB = new Date(parseInt(string2.substring(0, 4)), parseInt(string2.substring(4, 6)), parseInt(string2.substring(6, 8)));
              return dateA - dateB;
            });
            if (res.contract_date === null) {
              var totalDevis = [];
              var pageDevis = [];
              var countDevis = 0;
              sortResdev.forEach((devis, index) => {
                //console.log("devis : "+res.num_client+devis.Code_Client);
                if (res.num_client.indexOf(devis.Code_Client) !== -1) {
                  if (countDevis < 15) {
                    pageDevis.push(devis);
                    countDevis++;
                  }
                  else {
                    countDevis = 0;
                    totalDevis.push(pageDevis);
                    pageDevis = [];
                    pageDevis.push(devis);
                    countDevis++;
                  }
                }
  
              });
              if (pageDevis.length >= 1) {
                totalDevis.push(pageDevis);
              }
              this.setState({ devis: totalDevis });
              this.props.changeDevis(totalDevis);
            }
            else {
              var totalDevis = [];
              var pageDevis = [];
              var countDevis = 0;
              sortResdev.forEach((devis, index) => {
                if (res.num_client.indexOf(devis.Code_Client) !== -1) {
                  let contract = new Date(res.contract_date);
                  let devdate = new Date(parseInt(devis.Date_Crea_Devis.substring(0, 4)), parseInt(devis.Date_Crea_Devis.substring(4, 6)) - 1, parseInt(devis.Date_Crea_Devis.substring(6, 8)));
                  let test = devdate >= contract;
  
                  if (devdate >= contract) {
  
                    pageDevis.push(devis);
                    if (pageDevis.length === 15) {
                      totalDevis.push(pageDevis);
                      pageDevis = [];
                    }
  
                  }
                }
  
              });
              if (pageDevis.length >= 1) {
                totalDevis.push(pageDevis);
              }
              this.setState({ devis: totalDevis });
              this.props.changeDevis(totalDevis);
  
            }
            var test2 = true;
            resdev.forEach((commande, index) => {
  
              tabGroupe.forEach((tab, index) => {
                if (tab.localeCompare(commande['Libelle_Client']) === 0) {
                  test2 = false;
                }
  
              });
              if (test2) {
                tabGroupe.push(commande['Libelle_Client']);
              }
              test2 = true;
            });
          }
          
        });



        commande(res.num_client).then(rescmd => {
          if(typeof rescmd !== "undefined"){
            var sortRescmd = rescmd.sort(function compare(b, a) {
              var string1;
              var string2;
              if (a.Date_Saisie_Commande) {
                string1 = a.Date_Saisie_Commande.toString();
              }
              else {
                string1 = "";
              }
              if (b.Date_Saisie_Commande) {
                string2 = b.Date_Saisie_Commande.toString();
              }
              else {
                string2 = "";
              }
  
              var dateA = new Date(parseInt(string1.substring(0, 4)), parseInt(string1.substring(4, 6)), parseInt(string1.substring(6, 8)));
              var dateB = new Date(parseInt(string2.substring(0, 4)), parseInt(string2.substring(4, 6)), parseInt(string2.substring(6, 8)));
              return dateA - dateB;
            });
            if (res.contract_date === null) {
              var totalDevis = [];
              var pageDevis = [];
              var countDevis = 0;
              sortRescmd.forEach((devis, index) => {
                console.log("devis : " + res.num_client + devis.Code_Client);
                if (res.num_client.indexOf(devis.Code_Client) !== -1) {
                  if (countDevis < 15) {
                    pageDevis.push(devis);
                    countDevis++;
                  }
                  else {
                    countDevis = 0;
                    totalDevis.push(pageDevis);
                    pageDevis = [];
                    pageDevis.push(devis);
                    countDevis++;
                  }
                }
              });
              if (pageDevis.length >= 1) {
                totalDevis.push(pageDevis);
              }
              this.setState({ commande: totalDevis });
              this.props.changeCommande(totalDevis);
            }
            else {
              var totalDevis = [];
              var pageDevis = [];
              var countDevis = 0;
  
              sortRescmd.forEach((devis, index) => {
                if (res.num_client.indexOf(devis.Code_Client) !== -1) {
                  console.log("cmd " + devis);
                  let contract = new Date(res.contract_date);
                  let devdate = new Date(parseInt(devis.Date_Saisie_Commande.substring(0, 4)), parseInt(devis.Date_Saisie_Commande.substring(4, 6)) - 1, parseInt(devis.Date_Saisie_Commande.substring(6, 8)));
                  let test = devdate >= contract;
  
                  if (devdate >= contract) {
  
                    pageDevis.push(devis);
                    if (pageDevis.length === 15) {
                      totalDevis.push(pageDevis);
                      pageDevis = [];
                    }
  
                  }
                }
  
              });
              if (pageDevis.length >= 1) {
                totalDevis.push(pageDevis);
              }
              this.setState({ commande: totalDevis });
              this.props.changeCommande(totalDevis);
  
            }
  
            var testCom = false;
  
            if (res != null) {
              var test2 = true;
              rescmd.forEach((commande, index) => {
  
                tabGroupe.forEach((tab, index) => {
                  if (tab.localeCompare(commande['Libelle_Client']) === 0) {
                    test2 = false;
                  }
  
                });
                if (test2) {
                  tabGroupe.push(commande['Libelle_Client']);
                }
                test2 = true;
              });
            }
  
  
  
  
            this.setState({ tabGroupe: tabGroupe });
            this.props.changeTabGroupe(tabGroupe);
  
  
            var test2 = true;
            rescmd.forEach((commande, index) => {
  
              tabGroupe.forEach((tab, index) => {
                if (tab.localeCompare(commande['Libelle_Client']) === 0) {
                  test2 = false;
                }
  
              });
              if (test2) {
                tabGroupe.push(commande['Libelle_Client']);
              }
              test2 = true;
            });
  
  
  
            this.setState({ tabGroupe: tabGroupe });
            this.props.changeTabGroupe(tabGroupe);
  
  
            devis(res.num_client).then(res_devis => {
              var oney0 = 0;
              var twoy0 = 0;
              var threey0 = 0;
              var foury0 = 0;
              var fivey0 = 0;
              var sixy0 = 0;
              var seveny0 = 0;
              var eighty0 = 0;
              var niney0 = 0;
              var teny0 = 0;
              var eleveny0 = 0;
              var twelvey0 = 0;
  
              var oney1 = 0;
              var twoy1 = 0;
              var threey1 = 0;
              var foury1 = 0;
              var fivey1 = 0;
              var sixy1 = 0;
              var seveny1 = 0;
              var eighty1 = 0;
              var niney1 = 0;
              var teny1 = 0;
              var eleveny1 = 0;
              var twelvey1 = 0;
  
              var one1y0 = 0;
              var two1y0 = 0;
              var three1y0 = 0;
              var four1y0 = 0;
              var five1y0 = 0;
              var six1y0 = 0;
              var seven1y0 = 0;
              var eight1y0 = 0;
              var nine1y0 = 0;
              var ten1y0 = 0;
              var eleven1y0 = 0;
              var twelve1y0 = 0;
  
              var one1y1 = 0;
              var two1y1 = 0;
              var three1y1 = 0;
              var four1y1 = 0;
              var five1y1 = 0;
              var six1y1 = 0;
              var seven1y1 = 0;
              var eight1y1 = 0;
              var nine1y1 = 0;
              var ten1y1 = 0;
              var eleven1y1 = 0;
              var twelve1y1 = 0;
  
              var one2y0 = 0;
              var two2y0 = 0;
              var three2y0 = 0;
              var four2y0 = 0;
              var five2y0 = 0;
              var six2y0 = 0;
              var seven2y0 = 0;
              var eight2y0 = 0;
              var nine2y0 = 0;
              var ten2y0 = 0;
              var eleven2y0 = 0;
              var twelve2y0 = 0;
  
              var one2y1 = 0;
              var two2y1 = 0;
              var three2y1 = 0;
              var four2y1 = 0;
              var five2y1 = 0;
              var six2y1 = 0;
              var seven2y1 = 0;
              var eight2y1 = 0;
              var nine2y1 = 0;
              var ten2y1 = 0;
              var eleven2y1 = 0;
              var twelve2y1 = 0;
  
              tabGroupe.forEach((tab, index) => {
  
                res_devis.forEach((devis, index) => {
                  if (res.num_client.indexOf(devis.Code_Client) !== -1) {
                    if ((devis["Date_Crea_Devis"].substring(0, 4).localeCompare("2020")) === 0) {
                      if (tab.localeCompare(devis['Libelle_Client']) === 0 || tab.localeCompare("TOUS") === 0) {
                        if ((devis["Date_Crea_Devis"].substring(4, 6).localeCompare("01")) === 0) {
                          oney0 += 1;
                        }
                        else if ((devis["Date_Crea_Devis"].substring(4, 6).localeCompare("02")) === 0) {
                          twoy0 += 1;
                        }
                        else if ((devis["Date_Crea_Devis"].substring(4, 6).localeCompare("03")) === 0) {
                          threey0 += 1;
                        }
                        else if ((devis["Date_Crea_Devis"].substring(4, 6).localeCompare("04")) === 0) {
                          foury0 += 1;
                        }
                        else if ((devis["Date_Crea_Devis"].substring(4, 6).localeCompare("05")) === 0) {
                          fivey0 += 1;
                        }
                        else if ((devis["Date_Crea_Devis"].substring(4, 6).localeCompare("06")) === 0) {
                          sixy0 += 1;
                        }
                        else if ((devis["Date_Crea_Devis"].substring(4, 6).localeCompare("07")) === 0) {
                          seveny0 += 1;
                        }
                        else if ((devis["Date_Crea_Devis"].substring(4, 6).localeCompare("08")) === 0) {
                          eighty0 += 1;
                        }
                        else if ((devis["Date_Crea_Devis"].substring(4, 6).localeCompare("09")) === 0) {
                          niney0 += 1;
                        }
                        else if ((devis["Date_Crea_Devis"].substring(4, 6).localeCompare("10")) === 0) {
                          teny0 += 1;
                        }
                        else if ((devis["Date_Crea_Devis"].substring(4, 6).localeCompare("11")) === 0) {
                          eleveny0 += 1;
                        }
                        else if ((devis["Date_Crea_Devis"].substring(4, 6).localeCompare("12")) === 0) {
                          twelvey0 += 1;
                        }
                      }
                    }
                    else if ((devis["Date_Crea_Devis"].substring(0, 4).localeCompare("2021")) === 0) {
                      if (tab.localeCompare(devis['Libelle_Client']) === 0 || tab.localeCompare("TOUS") === 0) {
                        if ((devis["Date_Crea_Devis"].substring(4, 6).localeCompare("01")) === 0) {
                          oney1 += 1;
                        }
                        else if ((devis["Date_Crea_Devis"].substring(4, 6).localeCompare("02")) === 0) {
                          twoy1 += 1;
                        }
                        else if ((devis["Date_Crea_Devis"].substring(4, 6).localeCompare("03")) === 0) {
                          threey1 += 1;
                        }
                        else if ((devis["Date_Crea_Devis"].substring(4, 6).localeCompare("04")) === 0) {
                          foury1 += 1;
                        }
                        else if ((devis["Date_Crea_Devis"].substring(4, 6).localeCompare("05")) === 0) {
                          fivey1 += 1;
                        }
                        else if ((devis["Date_Crea_Devis"].substring(4, 6).localeCompare("06")) === 0) {
                          sixy1 += 1;
                        }
                        else if ((devis["Date_Crea_Devis"].substring(4, 6).localeCompare("07")) === 0) {
                          seveny1 += 1;
                        }
                        else if ((devis["Date_Crea_Devis"].substring(4, 6).localeCompare("08")) === 0) {
                          eighty1 += 1;
                        }
                        else if ((devis["Date_Crea_Devis"].substring(4, 6).localeCompare("09")) === 0) {
                          niney1 += 1;
                        }
                        else if ((devis["Date_Crea_Devis"].substring(4, 6).localeCompare("10")) === 0) {
                          teny1 += 1;
                        }
                        else if ((devis["Date_Crea_Devis"].substring(4, 6).localeCompare("11")) === 0) {
                          eleveny1 += 1;
                        }
                        else if ((devis["Date_Crea_Devis"].substring(4, 6).localeCompare("12")) === 0) {
                          twelvey1 += 1;
                        }
                      }
  
                    }
                  }
  
  
  
                });
  
                rescmd.forEach((commande, index) => {
                  /* res.forEach((devis,index) => {
                     if(commande["Num_Devis"].localeCompare(devis["Num_Devis"])===0){
                         if((devis["Date_Crea_Devis"].substring(0,4).localeCompare("2020"))===0){
                    if(tab.localeCompare(devis['Libelle_Client'])===0 || tab.localeCompare("TOUS")===0){
                     if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("01"))===0){
                       one1y0+=1;
                     }
                     else if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("02"))===0){
                       two1y0+=1;
                     }
                     else if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("03"))===0){
                       three1y0+=1;
                     }
                     else if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("04"))===0){
                       four1y0+=1;
                     }
                     else if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("05"))===0){
                       five1y0+=1;
                     }
                     else if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("06"))===0){
                       six1y0+=1;
                     }
                     else if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("07"))===0){
                       seven1y0+=1;
                     }
                     else if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("08"))===0){
                       eight1y0+=1;
                     }
                     else if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("09"))===0){
                       nine1y0+=1;
                     }
                     else if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("10"))===0){
                       ten1y0+=1;
                     }
                     else if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("11"))===0){
                       eleven1y0+=1;
                     }
                     else if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("12"))===0){
                       twelve1y0+=1;
                     }
                   }
                 }
                 else if((devis["Date_Crea_Devis"].substring(0,4).localeCompare("2021"))===0){
                   if(tab.localeCompare(devis['Libelle_Client'])===0 || tab.localeCompare("TOUS")===0){
                     if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("01"))===0){
                       one1y1+=1;
                     }
                     else if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("02"))===0){
                       two1y1+=1;
                     }
                     else if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("03"))===0){
                       three1y1+=1;
                     }
                     else if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("04"))===0){
                       four1y1+=1;
                     }
                     else if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("05"))===0){
                       five1y1+=1;
                     }
                     else if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("06"))===0){
                       six1y1+=1;
                     }
                     else if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("07"))===0){
                       seven1y1+=1;
                     }
                     else if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("08"))===0){
                       eight1y1+=1;
                     }
                     else if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("09"))===0){
                       nine1y1+=1;
                     }
                     else if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("10"))===0){
                       ten1y1+=1;
                     }
                     else if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("11"))===0){
                       eleven1y1+=1;
                     }
                     else if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("12"))===0){
                       twelve1y1+=1;
                     }
                   }
                 }
                       
                     }
                   });*/
  
  
  
                  if ((commande["Date_Saisie_Commande"].substring(0, 4).localeCompare("2020")) === 0) {
                    if (tab.localeCompare(commande['Libelle_Client']) === 0 || tab.localeCompare("TOUS") === 0) {
                      if ((commande["Date_Saisie_Commande"].substring(4, 6).localeCompare("01")) === 0) {
                        one2y0 += 1;
                      }
                      else if ((commande["Date_Saisie_Commande"].substring(4, 6).localeCompare("02")) === 0) {
                        two2y0 += 1;
                      }
                      else if ((commande["Date_Saisie_Commande"].substring(4, 6).localeCompare("03")) === 0) {
                        three2y0 += 1;
                      }
                      else if ((commande["Date_Saisie_Commande"].substring(4, 6).localeCompare("04")) === 0) {
                        four2y0 += 1;
                      }
                      else if ((commande["Date_Saisie_Commande"].substring(4, 6).localeCompare("05")) === 0) {
                        five2y0 += 1;
                      }
                      else if ((commande["Date_Saisie_Commande"].substring(4, 6).localeCompare("06")) === 0) {
                        six2y0 += 1;
                      }
                      else if ((commande["Date_Saisie_Commande"].substring(4, 6).localeCompare("07")) === 0) {
                        seven2y0 += 1;
                      }
                      else if ((commande["Date_Saisie_Commande"].substring(4, 6).localeCompare("08")) === 0) {
                        eight2y0 += 1;
                      }
                      else if ((commande["Date_Saisie_Commande"].substring(4, 6).localeCompare("09")) === 0) {
                        nine2y0 += 1;
                      }
                      else if ((commande["Date_Saisie_Commande"].substring(4, 6).localeCompare("10")) === 0) {
                        ten2y0 += 1;
                      }
                      else if ((commande["Date_Saisie_Commande"].substring(4, 6).localeCompare("11")) === 0) {
                        eleven2y0 += 1;
                      }
                      else if ((commande["Date_Saisie_Commande"].substring(4, 6).localeCompare("12")) === 0) {
                        twelve2y0 += 1;
                      }
                    }
                  }
                  else if ((commande["Date_Saisie_Commande"].substring(0, 4).localeCompare("2021")) === 0) {
                    if (tab.localeCompare(commande['Libelle_Client']) === 0 || tab.localeCompare("TOUS") === 0) {
                      if ((commande["Date_Saisie_Commande"].substring(4, 6).localeCompare("01")) === 0) {
                        one2y1 += 1;
                      }
                      else if ((commande["Date_Saisie_Commande"].substring(4, 6).localeCompare("02")) === 0) {
                        two2y1 += 1;
                      }
                      else if ((commande["Date_Saisie_Commande"].substring(4, 6).localeCompare("03")) === 0) {
                        three2y1 += 1;
                      }
                      else if ((commande["Date_Saisie_Commande"].substring(4, 6).localeCompare("04")) === 0) {
                        four2y1 += 1;
                      }
                      else if ((commande["Date_Saisie_Commande"].substring(4, 6).localeCompare("05")) === 0) {
                        five2y1 += 1;
                      }
                      else if ((commande["Date_Saisie_Commande"].substring(4, 6).localeCompare("06")) === 0) {
                        six2y1 += 1;
                      }
                      else if ((commande["Date_Saisie_Commande"].substring(4, 6).localeCompare("07")) === 0) {
                        seven2y1 += 1;
                      }
                      else if ((commande["Date_Saisie_Commande"].substring(4, 6).localeCompare("08")) === 0) {
                        eight2y1 += 1;
                      }
                      else if ((commande["Date_Saisie_Commande"].substring(4, 6).localeCompare("09")) === 0) {
                        nine2y1 += 1;
                      }
                      else if ((commande["Date_Saisie_Commande"].substring(4, 6).localeCompare("10")) === 0) {
                        ten2y1 += 1;
                      }
                      else if ((commande["Date_Saisie_Commande"].substring(4, 6).localeCompare("11")) === 0) {
                        eleven2y1 += 1;
                      }
                      else if ((commande["Date_Saisie_Commande"].substring(4, 6).localeCompare("12")) === 0) {
                        twelve2y1 += 1;
                      }
                    }
  
                  }
  
  
  
  
  
                });
                tabData1.push([[oney0, twoy0, threey0, foury0, fivey0, sixy0, seveny0, eighty0, niney0, teny0, eleveny0, twelvey0], [oney1, twoy1, threey1, foury1, fivey1, sixy1, seveny1, eighty1, niney1, teny1, eleveny1, twelvey1]]);
                //tabData2.push([[one1y0,two1y0,three1y0,four1y0,five1y0,six1y0,seven1y0,eight1y0,nine1y0,ten1y0,eleven1y0,twelve1y0],[one1y1,two1y1,three1y1,four1y1,five1y1,six1y1,seven1y1,eight1y1,nine1y1,ten1y1,eleven1y1,twelve1y1]]);
                tabData3.push([[one2y0, two2y0, three2y0, four2y0, five2y0, six2y0, seven2y0, eight2y0, nine2y0, ten2y0, eleven2y0, twelve2y0], [one2y1, two2y1, three2y1, four2y1, five2y1, six2y1, seven2y1, eight2y1, nine2y1, ten2y1, eleven2y1, twelve2y1]]);
  
                oney0 = 0;
                twoy0 = 0;
                threey0 = 0;
                foury0 = 0;
                fivey0 = 0;
                sixy0 = 0;
                seveny0 = 0;
                eighty0 = 0;
                niney0 = 0;
                teny0 = 0;
                eleveny0 = 0;
                twelvey0 = 0;
  
                oney1 = 0;
                twoy1 = 0;
                threey1 = 0;
                foury1 = 0;
                fivey1 = 0;
                sixy1 = 0;
                seveny1 = 0;
                eighty1 = 0;
                niney1 = 0;
                teny1 = 0;
                eleveny1 = 0;
                twelvey1 = 0;
  
                one1y0 = 0;
                two1y0 = 0;
                three1y0 = 0;
                four1y0 = 0;
                five1y0 = 0;
                six1y0 = 0;
                seven1y0 = 0;
                eight1y0 = 0;
                nine1y0 = 0;
                ten1y0 = 0;
                eleven1y0 = 0;
                twelve1y0 = 0;
  
                one1y1 = 0;
                two1y1 = 0;
                three1y1 = 0;
                four1y1 = 0;
                five1y1 = 0;
                six1y1 = 0;
                seven1y1 = 0;
                eight1y1 = 0;
                nine1y1 = 0;
                ten1y1 = 0;
                eleven1y1 = 0;
                twelve1y1 = 0;
  
                one2y0 = 0;
                two2y0 = 0;
                three2y0 = 0;
                four2y0 = 0;
                five2y0 = 0;
                six2y0 = 0;
                seven2y0 = 0;
                eight2y0 = 0;
                nine2y0 = 0;
                ten2y0 = 0;
                eleven2y0 = 0;
                twelve2y0 = 0;
  
  
                one2y1 = 0;
                two2y1 = 0;
                three2y1 = 0;
                four2y1 = 0;
                five2y1 = 0;
                six2y1 = 0;
                seven2y1 = 0;
                eight2y1 = 0;
                nine2y1 = 0;
                ten2y1 = 0;
                eleven2y1 = 0;
                twelve2y1 = 0;
              });
              //console.log(tabData3);
              this.setState({ tabData1: tabData1 });
              this.props.changeTabData1(tabData1);
              //this.setState({tabData2:tabData2});
              //this.props.changeTabData2(tabData2);
              this.setState({ tabData3: tabData3 });
              this.props.changeTabData3(tabData3);
  
  
  
  
  
            });

          }
        });


        facture(res.num_client).then(resfac => {
          if (typeof resfac !== "undefined") {

            var sortRescmd = resfac.sort(function compare(b, a) {
              var string1;
              var string2;
              if (a.Date_Saisie_Commande) {
                string1 = a.Date_Saisie_Commande.toString();
              }
              else {
                string1 = "";
              }
              if (b.Date_Saisie_Commande) {
                string2 = b.Date_Saisie_Commande.toString();
              }
              else {
                string2 = "";
              }

              var dateA = new Date(parseInt(string1.substring(0, 4)), parseInt(string1.substring(4, 6)), parseInt(string1.substring(6, 8)));
              var dateB = new Date(parseInt(string2.substring(0, 4)), parseInt(string2.substring(4, 6)), parseInt(string2.substring(6, 8)));
              return dateA - dateB;
            });
            if (res.contract_date === null) {
              var totalDevis = [];
              var pageDevis = [];
              var countDevis = 0;
              sortRescmd.forEach((devis, index) => {

                if (res.num_client.indexOf(devis.Code_Client) !== -1 && devis.Num_Commande.localeCompare("A2110VTF01317") !== 0 && devis.Num_Commande.localeCompare("F2111VTF00764") !== 0) {
                  console.log("test_facture " + resfac.Num_Commande + " " + resfac.Code_Client);
                  if (countDevis < 12) {
                    pageDevis.push(devis);
                    countDevis++;
                  }
                  else {
                    countDevis = 0;
                    totalDevis.push(pageDevis);
                    pageDevis = [];
                    pageDevis.push(devis);
                    countDevis++;
                  }
                }


              });
              if (pageDevis.length >= 1) {
                totalDevis.push(pageDevis);
              }
              this.setState({ facture: totalDevis });
              this.props.changeFacture(totalDevis);
            }
            else {
              var totalDevis = [];
              var pageDevis = [];
              var countDevis = 0;

              sortRescmd.forEach((devis, index) => {
                if (res.num_client.indexOf(devis.Code_Client) !== -1 && devis.Num_Commande.localeCompare("A2110VTF01317") !== 0 && devis.Num_Commande.localeCompare("F2111VTF00764") !== 0) {
                  console.log("cmd " + devis);
                  let contract = new Date(res.contract_date);
                  let devdate = new Date(parseInt(devis.Date_Comptable.substring(0, 4)), parseInt(devis.Date_Comptable.substring(4, 6)) - 1, parseInt(devis.Date_Comptable.substring(6, 8)));
                  let test = devdate >= contract;

                  if (devdate >= contract) {

                    pageDevis.push(devis);
                    if (pageDevis.length === 12) {
                      totalDevis.push(pageDevis);
                      pageDevis = [];
                    }

                  }
                }

              });
              if (pageDevis.length >= 1) {
                totalDevis.push(pageDevis);
              }
              this.setState({ facture: totalDevis });
              this.props.changeFacture(totalDevis);

            }
          }
        });
      }

      else {



        devis_groupe(res.groupe).then(resdev => {
          console.log("res contract : " + res.contract_date);
          var sortResdev = resdev.sort(function compare(b, a) {
            var string1 = a.Date_Crea_Devis.toString();
            var string2 = b.Date_Crea_Devis.toString();
            var dateA = new Date(parseInt(string1.substring(0, 4)), parseInt(string1.substring(4, 6)), parseInt(string1.substring(6, 8)));
            var dateB = new Date(parseInt(string2.substring(0, 4)), parseInt(string2.substring(4, 6)), parseInt(string2.substring(6, 8)));
            return dateA - dateB;
          });
          if (res.contract_date === null) {
            var totalDevis = [];
            var pageDevis = [];
            var countDevis = 0;
            sortResdev.forEach((devis, index) => {
              if (countDevis < 15) {
                pageDevis.push(devis);
                countDevis++;
              }
              else {
                countDevis = 0;
                totalDevis.push(pageDevis);
                pageDevis = [];
                pageDevis.push(devis);
                countDevis++;
              }
            });
            if (pageDevis.length >= 1) {
              totalDevis.push(pageDevis);
            }
            this.setState({ devis: totalDevis });
            this.props.changeDevis(totalDevis);
          }
          else {
            var totalDevis = [];
            var pageDevis = [];
            var countDevis = 0;
            sortResdev.forEach((devis, index) => {
              let contract = new Date(res.contract_date);
              let devdate = new Date(parseInt(devis.Date_Crea_Devis.substring(0, 4)), parseInt(devis.Date_Crea_Devis.substring(4, 6)) - 1, parseInt(devis.Date_Crea_Devis.substring(6, 8)));
              let test = devdate >= contract;

              if (devdate >= contract) {

                pageDevis.push(devis);
                if (pageDevis.length === 15) {
                  totalDevis.push(pageDevis);
                  pageDevis = [];
                }

              }
            });
            if (pageDevis.length >= 1) {
              totalDevis.push(pageDevis);
            }
            this.setState({ devis: totalDevis });
            this.props.changeDevis(totalDevis);

          }
          var test2 = true;
          resdev.forEach((commande, index) => {

            tabGroupe.forEach((tab, index) => {
              if (tab.localeCompare(commande['Libelle_Client']) === 0) {
                test2 = false;
              }

            });
            if (test2) {
              tabGroupe.push(commande['Libelle_Client']);
            }
            test2 = true;
          });
        });
        commande_groupe(res.groupe).then(rescmd => {
          var sortRescmd = rescmd.sort(function compare(b, a) {
            var string1;
            var string2;
            if (a.Date_Saisie_Commande) {
              string1 = a.Date_Saisie_Commande.toString();
            }
            else {
              string1 = "";
            }
            if (b.Date_Saisie_Commande) {
              string2 = b.Date_Saisie_Commande.toString();
            }
            else {
              string2 = "";
            }

            var dateA = new Date(parseInt(string1.substring(0, 4)), parseInt(string1.substring(4, 6)), parseInt(string1.substring(6, 8)));
            var dateB = new Date(parseInt(string2.substring(0, 4)), parseInt(string2.substring(4, 6)), parseInt(string2.substring(6, 8)));
            return dateA - dateB;
          });
          if (res.contract_date === null) {
            var totalDevis = [];
            var pageDevis = [];
            var countDevis = 0;
            sortRescmd.forEach((devis, index) => {

              if (countDevis < 15) {
                pageDevis.push(devis);
                countDevis++;
              }
              else {
                countDevis = 0;
                totalDevis.push(pageDevis);
                pageDevis = [];
                pageDevis.push(devis);
                countDevis++;
              }
            });
            if (pageDevis.length >= 1) {
              totalDevis.push(pageDevis);
            }
            this.setState({ commande: totalDevis });
            this.props.changeCommande(totalDevis);
          }
          else {
            var totalDevis = [];
            var pageDevis = [];
            var countDevis = 0;

            sortRescmd.forEach((devis, index) => {
              console.log("cmd " + devis);
              let contract = new Date(res.contract_date);
              let devdate = new Date(parseInt(devis.Date_Saisie_Commande.substring(0, 4)), parseInt(devis.Date_Saisie_Commande.substring(4, 6)) - 1, parseInt(devis.Date_Saisie_Commande.substring(6, 8)));
              let test = devdate >= contract;

              if (devdate >= contract) {

                pageDevis.push(devis);
                if (pageDevis.length === 15) {
                  totalDevis.push(pageDevis);
                  pageDevis = [];
                }

              }
            });
            if (pageDevis.length >= 1) {
              totalDevis.push(pageDevis);
            }
            this.setState({ commande: totalDevis });
            this.props.changeCommande(totalDevis);

          }
          var test2 = true;
          rescmd.forEach((commande, index) => {

            tabGroupe.forEach((tab, index) => {
              if (tab.localeCompare(commande['Libelle_Client']) === 0) {
                test2 = false;
              }

            });
            if (test2) {
              tabGroupe.push(commande['Libelle_Client']);
            }
            test2 = true;
          });



          this.setState({ tabGroupe: tabGroupe });
          this.props.changeTabGroupe(tabGroupe);


          devis_groupe(res.groupe).then(res => {
            var oney0 = 0;
            var twoy0 = 0;
            var threey0 = 0;
            var foury0 = 0;
            var fivey0 = 0;
            var sixy0 = 0;
            var seveny0 = 0;
            var eighty0 = 0;
            var niney0 = 0;
            var teny0 = 0;
            var eleveny0 = 0;
            var twelvey0 = 0;

            var oney1 = 0;
            var twoy1 = 0;
            var threey1 = 0;
            var foury1 = 0;
            var fivey1 = 0;
            var sixy1 = 0;
            var seveny1 = 0;
            var eighty1 = 0;
            var niney1 = 0;
            var teny1 = 0;
            var eleveny1 = 0;
            var twelvey1 = 0;

            var one1y0 = 0;
            var two1y0 = 0;
            var three1y0 = 0;
            var four1y0 = 0;
            var five1y0 = 0;
            var six1y0 = 0;
            var seven1y0 = 0;
            var eight1y0 = 0;
            var nine1y0 = 0;
            var ten1y0 = 0;
            var eleven1y0 = 0;
            var twelve1y0 = 0;

            var one1y1 = 0;
            var two1y1 = 0;
            var three1y1 = 0;
            var four1y1 = 0;
            var five1y1 = 0;
            var six1y1 = 0;
            var seven1y1 = 0;
            var eight1y1 = 0;
            var nine1y1 = 0;
            var ten1y1 = 0;
            var eleven1y1 = 0;
            var twelve1y1 = 0;

            var one2y0 = 0;
            var two2y0 = 0;
            var three2y0 = 0;
            var four2y0 = 0;
            var five2y0 = 0;
            var six2y0 = 0;
            var seven2y0 = 0;
            var eight2y0 = 0;
            var nine2y0 = 0;
            var ten2y0 = 0;
            var eleven2y0 = 0;
            var twelve2y0 = 0;

            var one2y1 = 0;
            var two2y1 = 0;
            var three2y1 = 0;
            var four2y1 = 0;
            var five2y1 = 0;
            var six2y1 = 0;
            var seven2y1 = 0;
            var eight2y1 = 0;
            var nine2y1 = 0;
            var ten2y1 = 0;
            var eleven2y1 = 0;
            var twelve2y1 = 0;


            tabGroupe.forEach((tab, index) => {

              res.forEach((devis, index) => {
                if ((devis["Date_Crea_Devis"].substring(0, 4).localeCompare("2020")) === 0) {
                  if (tab.localeCompare(devis['Libelle_Client']) === 0 || tab.localeCompare("TOUS") === 0) {
                    if ((devis["Date_Crea_Devis"].substring(4, 6).localeCompare("01")) === 0) {
                      oney0 += 1;
                    }
                    else if ((devis["Date_Crea_Devis"].substring(4, 6).localeCompare("02")) === 0) {
                      twoy0 += 1;
                    }
                    else if ((devis["Date_Crea_Devis"].substring(4, 6).localeCompare("03")) === 0) {
                      threey0 += 1;
                    }
                    else if ((devis["Date_Crea_Devis"].substring(4, 6).localeCompare("04")) === 0) {
                      foury0 += 1;
                    }
                    else if ((devis["Date_Crea_Devis"].substring(4, 6).localeCompare("05")) === 0) {
                      fivey0 += 1;
                    }
                    else if ((devis["Date_Crea_Devis"].substring(4, 6).localeCompare("06")) === 0) {
                      sixy0 += 1;
                    }
                    else if ((devis["Date_Crea_Devis"].substring(4, 6).localeCompare("07")) === 0) {
                      seveny0 += 1;
                    }
                    else if ((devis["Date_Crea_Devis"].substring(4, 6).localeCompare("08")) === 0) {
                      eighty0 += 1;
                    }
                    else if ((devis["Date_Crea_Devis"].substring(4, 6).localeCompare("09")) === 0) {
                      niney0 += 1;
                    }
                    else if ((devis["Date_Crea_Devis"].substring(4, 6).localeCompare("10")) === 0) {
                      teny0 += 1;
                    }
                    else if ((devis["Date_Crea_Devis"].substring(4, 6).localeCompare("11")) === 0) {
                      eleveny0 += 1;
                    }
                    else if ((devis["Date_Crea_Devis"].substring(4, 6).localeCompare("12")) === 0) {
                      twelvey0 += 1;
                    }
                  }
                }
                else if ((devis["Date_Crea_Devis"].substring(0, 4).localeCompare("2021")) === 0) {
                  if (tab.localeCompare(devis['Libelle_Client']) === 0 || tab.localeCompare("TOUS") === 0) {
                    if ((devis["Date_Crea_Devis"].substring(4, 6).localeCompare("01")) === 0) {
                      oney1 += 1;
                    }
                    else if ((devis["Date_Crea_Devis"].substring(4, 6).localeCompare("02")) === 0) {
                      twoy1 += 1;
                    }
                    else if ((devis["Date_Crea_Devis"].substring(4, 6).localeCompare("03")) === 0) {
                      threey1 += 1;
                    }
                    else if ((devis["Date_Crea_Devis"].substring(4, 6).localeCompare("04")) === 0) {
                      foury1 += 1;
                    }
                    else if ((devis["Date_Crea_Devis"].substring(4, 6).localeCompare("05")) === 0) {
                      fivey1 += 1;
                    }
                    else if ((devis["Date_Crea_Devis"].substring(4, 6).localeCompare("06")) === 0) {
                      sixy1 += 1;
                    }
                    else if ((devis["Date_Crea_Devis"].substring(4, 6).localeCompare("07")) === 0) {
                      seveny1 += 1;
                    }
                    else if ((devis["Date_Crea_Devis"].substring(4, 6).localeCompare("08")) === 0) {
                      eighty1 += 1;
                    }
                    else if ((devis["Date_Crea_Devis"].substring(4, 6).localeCompare("09")) === 0) {
                      niney1 += 1;
                    }
                    else if ((devis["Date_Crea_Devis"].substring(4, 6).localeCompare("10")) === 0) {
                      teny1 += 1;
                    }
                    else if ((devis["Date_Crea_Devis"].substring(4, 6).localeCompare("11")) === 0) {
                      eleveny1 += 1;
                    }
                    else if ((devis["Date_Crea_Devis"].substring(4, 6).localeCompare("12")) === 0) {
                      twelvey1 += 1;
                    }
                  }

                }


              });

              rescmd.forEach((commande, index) => {



                if ((commande["Date_Saisie_Commande"].substring(0, 4).localeCompare("2020")) === 0) {
                  if (tab.localeCompare(commande['Libelle_Client']) === 0 || tab.localeCompare("TOUS") === 0) {
                    if ((commande["Date_Saisie_Commande"].substring(4, 6).localeCompare("01")) === 0) {
                      one2y0 += 1;
                    }
                    else if ((commande["Date_Saisie_Commande"].substring(4, 6).localeCompare("02")) === 0) {
                      two2y0 += 1;
                    }
                    else if ((commande["Date_Saisie_Commande"].substring(4, 6).localeCompare("03")) === 0) {
                      three2y0 += 1;
                    }
                    else if ((commande["Date_Saisie_Commande"].substring(4, 6).localeCompare("04")) === 0) {
                      four2y0 += 1;
                    }
                    else if ((commande["Date_Saisie_Commande"].substring(4, 6).localeCompare("05")) === 0) {
                      five2y0 += 1;
                    }
                    else if ((commande["Date_Saisie_Commande"].substring(4, 6).localeCompare("06")) === 0) {
                      six2y0 += 1;
                    }
                    else if ((commande["Date_Saisie_Commande"].substring(4, 6).localeCompare("07")) === 0) {
                      seven2y0 += 1;
                    }
                    else if ((commande["Date_Saisie_Commande"].substring(4, 6).localeCompare("08")) === 0) {
                      eight2y0 += 1;
                    }
                    else if ((commande["Date_Saisie_Commande"].substring(4, 6).localeCompare("09")) === 0) {
                      nine2y0 += 1;
                    }
                    else if ((commande["Date_Saisie_Commande"].substring(4, 6).localeCompare("10")) === 0) {
                      ten2y0 += 1;
                    }
                    else if ((commande["Date_Saisie_Commande"].substring(4, 6).localeCompare("11")) === 0) {
                      eleven2y0 += 1;
                    }
                    else if ((commande["Date_Saisie_Commande"].substring(4, 6).localeCompare("12")) === 0) {
                      twelve2y0 += 1;
                    }
                  }
                }
                else if ((commande["Date_Saisie_Commande"].substring(0, 4).localeCompare("2021")) === 0) {
                  if (tab.localeCompare(commande['Libelle_Client']) === 0 || tab.localeCompare("TOUS") === 0) {
                    if ((commande["Date_Saisie_Commande"].substring(4, 6).localeCompare("01")) === 0) {
                      one2y1 += 1;
                    }
                    else if ((commande["Date_Saisie_Commande"].substring(4, 6).localeCompare("02")) === 0) {
                      two2y1 += 1;
                    }
                    else if ((commande["Date_Saisie_Commande"].substring(4, 6).localeCompare("03")) === 0) {
                      three2y1 += 1;
                    }
                    else if ((commande["Date_Saisie_Commande"].substring(4, 6).localeCompare("04")) === 0) {
                      four2y1 += 1;
                    }
                    else if ((commande["Date_Saisie_Commande"].substring(4, 6).localeCompare("05")) === 0) {
                      five2y1 += 1;
                    }
                    else if ((commande["Date_Saisie_Commande"].substring(4, 6).localeCompare("06")) === 0) {
                      six2y1 += 1;
                    }
                    else if ((commande["Date_Saisie_Commande"].substring(4, 6).localeCompare("07")) === 0) {
                      seven2y1 += 1;
                    }
                    else if ((commande["Date_Saisie_Commande"].substring(4, 6).localeCompare("08")) === 0) {
                      eight2y1 += 1;
                    }
                    else if ((commande["Date_Saisie_Commande"].substring(4, 6).localeCompare("09")) === 0) {
                      nine2y1 += 1;
                    }
                    else if ((commande["Date_Saisie_Commande"].substring(4, 6).localeCompare("10")) === 0) {
                      ten2y1 += 1;
                    }
                    else if ((commande["Date_Saisie_Commande"].substring(4, 6).localeCompare("11")) === 0) {
                      eleven2y1 += 1;
                    }
                    else if ((commande["Date_Saisie_Commande"].substring(4, 6).localeCompare("12")) === 0) {
                      twelve2y1 += 1;
                    }
                  }

                }





              });
              tabData1.push([[oney0, twoy0, threey0, foury0, fivey0, sixy0, seveny0, eighty0, niney0, teny0, eleveny0, twelvey0], [oney1, twoy1, threey1, foury1, fivey1, sixy1, seveny1, eighty1, niney1, teny1, eleveny1, twelvey1]]);
              //tabData2.push([[one1y0,two1y0,three1y0,four1y0,five1y0,six1y0,seven1y0,eight1y0,nine1y0,ten1y0,eleven1y0,twelve1y0],[one1y1,two1y1,three1y1,four1y1,five1y1,six1y1,seven1y1,eight1y1,nine1y1,ten1y1,eleven1y1,twelve1y1]]);
              tabData3.push([[one2y0, two2y0, three2y0, four2y0, five2y0, six2y0, seven2y0, eight2y0, nine2y0, ten2y0, eleven2y0, twelve2y0], [one2y1, two2y1, three2y1, four2y1, five2y1, six2y1, seven2y1, eight2y1, nine2y1, ten2y1, eleven2y1, twelve2y1]]);

              oney0 = 0;
              twoy0 = 0;
              threey0 = 0;
              foury0 = 0;
              fivey0 = 0;
              sixy0 = 0;
              seveny0 = 0;
              eighty0 = 0;
              niney0 = 0;
              teny0 = 0;
              eleveny0 = 0;
              twelvey0 = 0;

              oney1 = 0;
              twoy1 = 0;
              threey1 = 0;
              foury1 = 0;
              fivey1 = 0;
              sixy1 = 0;
              seveny1 = 0;
              eighty1 = 0;
              niney1 = 0;
              teny1 = 0;
              eleveny1 = 0;
              twelvey1 = 0;

              one1y0 = 0;
              two1y0 = 0;
              three1y0 = 0;
              four1y0 = 0;
              five1y0 = 0;
              six1y0 = 0;
              seven1y0 = 0;
              eight1y0 = 0;
              nine1y0 = 0;
              ten1y0 = 0;
              eleven1y0 = 0;
              twelve1y0 = 0;

              one1y1 = 0;
              two1y1 = 0;
              three1y1 = 0;
              four1y1 = 0;
              five1y1 = 0;
              six1y1 = 0;
              seven1y1 = 0;
              eight1y1 = 0;
              nine1y1 = 0;
              ten1y1 = 0;
              eleven1y1 = 0;
              twelve1y1 = 0;

              one2y0 = 0;
              two2y0 = 0;
              three2y0 = 0;
              four2y0 = 0;
              five2y0 = 0;
              six2y0 = 0;
              seven2y0 = 0;
              eight2y0 = 0;
              nine2y0 = 0;
              ten2y0 = 0;
              eleven2y0 = 0;
              twelve2y0 = 0;


              one2y1 = 0;
              two2y1 = 0;
              three2y1 = 0;
              four2y1 = 0;
              five2y1 = 0;
              six2y1 = 0;
              seven2y1 = 0;
              eight2y1 = 0;
              nine2y1 = 0;
              ten2y1 = 0;
              eleven2y1 = 0;
              twelve2y1 = 0;
            });
            //console.log(tabData3);
            this.setState({ tabData1: tabData1 });
            this.props.changeTabData1(tabData1);
            // this.setState({tabData2:tabData2});
            // this.props.changeTabData2(tabData2);
            this.setState({ tabData3: tabData3 });
            this.props.changeTabData3(tabData3);


          });
        });



        facture_groupe(res.groupe).then(resfac => {
          var sortRescmd = resfac.sort(function compare(b, a) {
            var string1;
            var string2;
            if (a.Date_Saisie_Commande) {
              string1 = a.Date_Saisie_Commande.toString();
            }
            else {
              string1 = "";
            }
            if (b.Date_Saisie_Commande) {
              string2 = b.Date_Saisie_Commande.toString();
            }
            else {
              string2 = "";
            }

            var dateA = new Date(parseInt(string1.substring(0, 4)), parseInt(string1.substring(4, 6)), parseInt(string1.substring(6, 8)));
            var dateB = new Date(parseInt(string2.substring(0, 4)), parseInt(string2.substring(4, 6)), parseInt(string2.substring(6, 8)));
            return dateA - dateB;
          });
          if (res.contract_date === null) {
            var totalDevis = [];
            var pageDevis = [];
            var countDevis = 0;
            sortRescmd.forEach((devis, index) => {

              if (countDevis < 12) {
                pageDevis.push(devis);
                countDevis++;
              }
              else {
                countDevis = 0;
                totalDevis.push(pageDevis);
                pageDevis = [];
                pageDevis.push(devis);
                countDevis++;
              }
            });
            if (pageDevis.length >= 1) {
              totalDevis.push(pageDevis);
            }
            this.setState({ facture: totalDevis });
            this.props.changeFacture(totalDevis);
          }
          else {
            var totalDevis = [];
            var pageDevis = [];
            var countDevis = 0;

            sortRescmd.forEach((devis, index) => {
              console.log("cmd " + devis);
              let contract = new Date(res.contract_date);
              let devdate = new Date(parseInt(devis.Date_Comptable.substring(0, 4)), parseInt(devis.Date_Comptable.substring(4, 6)) - 1, parseInt(devis.Date_Comptable.substring(6, 8)));
              let test = devdate >= contract;

              if (devdate >= contract) {

                pageDevis.push(devis);
                if (pageDevis.length === 12) {
                  totalDevis.push(pageDevis);
                  pageDevis = [];
                }

              }
            });
            if (pageDevis.length >= 1) {
              totalDevis.push(pageDevis);
            }

            this.setState({ facture: totalDevis });
            this.props.changeFacture(totalDevis);

          }

        });
      }
    });


  }

  render() {
    let push = this.props.suivi_status;
    let check = this.checkLoginStatus();
    if (this.state.profile && this.state.devis && this.state.presta && this.state.facture && this.state.commande && this.state.img_list) {
      return (
        <div>
          {check}
          <Header title={this.state.title} />
          {push}
        </div>
      );

    }
    return (
      <div>
        <Chargement />
      </div>
    );
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(Connexion);