export const CHANGE_STATUS_SUIVI = "CHANGE_STATUS_SUIVI";
export const CHANGE_PAGE = "CHANGE_PAGE";
export const CHANGE_ISREVERSE = "CHANGE_ISREVERSE";
export const CHANGE_STYLEPROFIL = "CHANGE_STYLEPROFIL";
export const CHANGE_SUIVI_PAGE = "CHANGE_SUIVI_PAGE";
export const CHANGE_TAB_GROUPE = "CHANGE_TAB_GROUPE";
export const CHANGE_TAB_DATA1 = "CHANGE_TAB_DATA1";
export const CHANGE_TAB_DATA2 = "CHANGE_TAB_DATA2";
export const CHANGE_TAB_DATA3 = "CHANGE_TAB_DATA3";
export const CHANGE_ADM_PAGE = "CHANGE_ADM_PAGE";
export const CHANGE_COLORD = "CHANGE_COLORD";
export const CHANGE_COLORC = "CHANGE_COLORC";
export const CHANGE_COLORF = "CHANGE_COLORF";
export const CHANGE_COLORR = "CHANGE_COLORR";
export const CHANGE_BOOLP = "CHANGE_BOOLP";
export const CHANGE_TITLE = "CHANGE_TITLE";
export const CHANGE_STYLER = "CHANGE_STYLER";
export const CHANGE_STYLED = "CHANGE_STYLED";
export const CHANGE_STYLEC = "CHANGE_STYLEC";
export const CHANGE_STYLEP = "CHANGE_STYLEP";
export const CHANGE_STYLEF = "CHANGE_STYLEF";
export const CHANGE_PROFILE = "CHANGE_PROFILE";
export const CHANGE_COMMANDE = "CHANGE_COMMANDE";
export const CHANGE_DEVIS = "CHANGE_DEVIS";
export const CHANGE_DEB = "CHANGE_DEB";
export const CHANGE_NB_DEVIS = "CHANGE_NB_DEVIS";
export const CHANGE_FIND = "CHANGE_FIND";
export const CHANGE_STYLE_RETOUR = "CHANGE_STYLE_RETOUR";
export const CHANGE_FACTURE = "CHANGE_FACTURE";
export const CHANGE_AUTH = "CHANGE_AUTH";
export const CHANGE_RT = "CHANGE_RT";
export const CHANGE_CT = "CHANGE_CT";
export const CHANGE_DT = "CHANGE_DT";
export const CHANGE_DETAIL = "CHANGE_DETAIL";
export const CHANGE_FT = "CHANGE_FT";
export const CHANGE_PRESTA = "CHANGE_PRESTA";
export const CHANGE_IMG = "CHANGE_IMG";
export const CHANGE_CHARGE = "CHANGE_CHARGE";
export const CHANGE_IMGGET = "CHANGE_IMGGET";
export const CHANGE_CHOIXADM = "CHANGE_CHOIXADM";
export const CHANGE_ALL_USERS = "CHANGE_ALL_USERS";
export const CHANGE_ALL_LOGOS = "CHANGE_ALL_LOGOS";
export const CHANGE_PROFIL_ADM = "CHANGE_PROFIL_ADM";
export const CHANGE_LOGO_ADM = "CHANGE_LOGO_ADM";
export const FOUND_ERROR = "FOUND_ERROR";