import React, { Component } from 'react';
import '../css/profil.css';
import '../css/Login.css';
import '../css/Create_user.css';
import { connect } from "react-redux";
import logo_oeil from '../img/eye.png';
import logo_no_oeil from '../img/no-visibility.png';
import { register, sendImg } from '../api/UserFunctions';

const bcrypt = require('bcryptjs');


const mapStateToProps = state => {
  return { profile_user: state.profile_user };
};

class Create_logo extends Component {


  constructor(props){
    super(props);
    this.state = {
      etps:'',
      selectedFile: null,
      value_res:""
    }
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.handlePass1 = this.handlePass1.bind(this);
    this.handlePass1P = this.handlePass1P.bind(this);
    this.handlePass2 = this.handlePass2.bind(this);
    this.handlePass2P = this.handlePass2P.bind(this);
  }


  onSubmit(e) {
      e.preventDefault();

      if(true){

          const dataImg = {
                etps: this.state.etps,
                selectedFile: this.state.selectedFile
              }
              if(this.state.selectedFile !== null){
                sendImg(dataImg).then(res => {
                  
                  var keys = Object.keys(res);
                  if(keys[0].localeCompare("error")==0){
                    this.setState({value_res:"Un logo existe déjà pour cette entreprise"});
                  }
                  else{                  
                    this.setState({value_res:"Le logo a bien été enregistré"});
                  }
                });

              }
              else{
                this.setState({value_res:"Fichier vide"});
              }
              

              
              this.setState({incorrect3:{display:"block"}});
              this.setState({incorrect1:{display:"none"}});
              this.setState({incorrect2:{display:"none"}});
      }
      else{
            this.setState({incorrect1:{display:"block"}});
            this.setState({incorrect3:{display:"none"}});
            this.setState({incorrect2:{diplay:"none"}});
      }
    }


  onChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  handlePass1(e){
    this.setState({pass1:'text'});
    this.setState({test1:false});
  }

  handlePass1P(e){
    this.setState({pass1:'password'});
    this.setState({test1:true});
  }

   handlePass2(e){
    this.setState({pass2:'text'});
    this.setState({test2:false});
  }

  handlePass2P(e){
    this.setState({pass2:'password'});
    this.setState({test2:true});
  }

  onFileChange = event => { 
     
      // Update the state 
      this.setState({ selectedFile: event.target.files[0] }); 
     
    }; 



  render() {
    return (

    	<div className="create_user">
        <div id="block">
          <div id='img_p'>
          <form className="form_profile" id="profile2" onSubmit={this.onSubmit}>
                 <br/>
                <input
                  id="form_profile_mdp22"
                  type="text"
                  name="etps"
                  placeholder="Entreprise"
                  value={this.state.etps}
                  onChange={this.onChange}
                />
                <br/>
                <input id ="form_file" type="file" onChange={this.onFileChange} />
                <br />
                <button id="form_button" type="submit">Envoyer</button>
                <p>{this.state.value_res.toString()}</p>
              </form>
          </div>
        </div>
		  </div>
      );
    }
  }
export default connect(mapStateToProps)(Create_logo);


