import React, { Component } from 'react';
import '../css/App.css';
import Log_adm from './Login';
import { connect } from "react-redux";

const mapStateToProps = state => {
  return { adm_page: state.adm_page};
};



class App_adm extends Component {
  constructor(props){
    super(props);
  }

  render() {
    return (
      <div className="App" >
        {this.props.adm_page}
      </div>

      
    );
  }
}
export default connect(mapStateToProps)(App_adm);