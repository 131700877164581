import React, { Component } from 'react';
import logo from '../img/logo_cfi.png';
import logo_proviso from '../img/cfi_logo_proviso_home.svg';
import { login, mail } from '../api/UserFunctions';
import '../css/Login.css';
import Auth from '../auth/Auth';
import { connect } from "react-redux";
import { changeProfile, changeCommande, changeDevis, changeAuth } from "../actions/index";

function mapDispatchToProps(dispatch) {
  return {
    changeProfile: profile_user => dispatch(changeProfile(profile_user)),
    changeCommande: commande_user => dispatch(changeCommande(commande_user)),
    changeDevis: devis_user => dispatch(changeDevis(devis_user)),
    changeAuth: auth => dispatch(changeAuth(auth))
  };
}

class Connected_login extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      email_forgot: '',
      forget: { display: "block" },
      ok: 0,
      style_forget: { display: "none" },
      style_incorrect: { display: "none" },
      style_incorrect2: { display: "none" },
      style_incorrect3: { display: "none" },
      msg_forget: '',
      page: false,
      errors: {}
    }


    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.handleConnexion = this.handleConnexion.bind(this);
    this.logout = this.logout.bind(this);
  }


  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleForget() {
    this.setState({ style_forget: { display: "block" }, forget: { display: "block" } });
    this.setState({ page: true });
  }

  handleForget2(e) {
    e.preventDefault();
    if (this.state.email_forgot.localeCompare("") == 0) {
      this.setState({ msg_forget: "Email non conforme." })
    }
    else {
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!pattern.test(this.state.email_forgot)) {
        this.setState({ msg_forget: "Email non conforme." })
      }
      else {
        mail(this.state.email_forgot).then(res => {
        });
        this.setState({ forget: { display: "none" } });
        this.setState({ msg_forget: "Si votre email correspond, vous recevrez un mail contenant les informations nécessaires pour réinitialiser votre mot de passe." })
      }

    }



  }
  handlePage(event) {
    const target = event.target;
    if (target.id !== 'modal') {
      return;
    }
    this.setState({ style_forget: { display: "none" } });
    this.setState({ page: false });
    this.setState({ msg_forget: "" });

  }

  handleConnexion() {

  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }
  onSubmit(e) {
    this.setState({ essai: true });
    e.preventDefault();
    const user = {
      email: this.state.email,
      password: this.state.password,
      ok: this.state.ok
    }

    login(user).then(res => {

      this._isMounted = true;

      if (res) {
        localStorage.setItem('user', true);
        window.location.href = process.env.REACT_APP_ID_PROVISO + "/proviso_user";
      }
      else {

        if (user.ok === 400) {
          this.setState({ style_incorrect: { display: "block" } });
          this.setState({ style_incorrect3: { display: "none" } });
          this.setState({ style_incorrect2: { display: "none" } });

        }
        if (user.ok === 401) {
          this.setState({ style_incorrect2: { display: "block" } });
          this.setState({ style_incorrect: { display: "none" } });
          this.setState({ style_incorrect3: { display: "none" } });

        }
        if (user.ok === 429) {
          this.setState({ style_incorrect3: { display: "block" } });
          this.setState({ style_incorrect: { display: "none" } });
          this.setState({ style_incorrect2: { display: "none" } });

        }
      }


    })





  }


  login() {
    Auth.authenticate();
  }
  logout() {
    Auth.signout();
  }

  updateState() {
    this.setState({ error_msg: true });
  }


  render() {

    if (this.state.page) {



      return (
        <div id="page_connexion" onClick={(event) => this.handlePage(event)}>
          <div id="design1">
            <div id="decor">
              <div><img id="logo_cfi" src={logo} alt="logo de CFI" /></div>
              <img id="logo_proviso" src={logo_proviso} alt="logo de Proviso" />
              <p id="texte_accueil">Plateforme de suivi de commandes<br /> solutions et services</p>
            </div>
          </div>
          <div id="connexion">
            <div id="formulaire">
              <div id="titre">
                <h3>Identifiant</h3>
              </div>
              <form className="form_connexion" onSubmit={this.onSubmit}>
                <input
                  id="form_email"
                  type="email"
                  placeholder="Email professionnel"
                  name="email"
                  value={this.state.email}
                  onChange={this.onChange}
                />
                <br />
                <input
                  id="form_mdp"
                  type="password"
                  placeholder="Mot de passe"
                  name="password"
                  value={this.state.password}
                  onChange={this.onChange}
                />
                <br />
                <div id="this_button">
                  <button id="form_button" type="submit" onClick={(event) => this.redirectToHome}>SE CONNECTER</button>
                  <p id="link_mdp" onClick={(event) => this.handleForget()}>Mot de passe oublié</p>
                  <p id="incorrect" style={this.state.style_incorrect}>Email incorrect</p>
                  <p id="incorrect2" style={this.state.style_incorrect2}>Mot de passe incorrect</p>
                  <p id="incorrect3" style={this.state.style_incorrect3}>Trop de tentatives, veuillez réessayer dans 5 minutes</p>
                </div>
              </form>
            </div>
          </div>
          <div id="modal" style={this.state.style_forget} >

            <div className="modal-content" >
              <p id="forget_title">Récupération de votre mot de passe</p>
              <form style={this.state.forget} className="form_connexion">
                <input
                  id="form_email_forget"
                  type="email"
                  placeholder="Email professionnel"
                  name="email_forgot"
                  onChange={this.onChange}
                />
                <br />
                {/*<input
                  id="form_mdp_forget"
                  type="text"
                  placeholder="N° de compte client"
                  name="password"
                />*/}
                <br />
                <div id="this_button">
                  <button id="form_button_forget" onClick={(event) => this.handleForget2(event)}>Envoyer</button>
                </div>
              </form>
              <p style={{ fontSize: "1.2em" }}>{this.state.msg_forget}</p>
              {/*<p id="advice_forget">Vous devez saisir votre adresse email professionnelle et le N° de contrat qui commance par CXX suivis d'une série de chiffres</p>*/}
            </div>
          </div>
        </div>


      );

    }
    else {
      return (

        <div id="page_connexion" onClick={(event) => this.handlePage(event)}>
          <div id="design1">
            <div id="decor">
              <div><img id="logo_cfi" src={logo} alt="logo de CFI" /></div>
              <img id="logo_proviso" src={logo_proviso} alt="logo de Proviso" />
              <p id="texte_accueil">Plateforme de suivi de commandes<br /> solutions et services</p>
            </div>
          </div>
          <div id="connexion">
            <div id="formulaire">
              <div id="titre">
                <h3>Identifiant</h3>
              </div>
              <form className="form_connexion" onSubmit={this.onSubmit}>
                <input
                  id="form_email"
                  type="email"
                  placeholder="Email professionnel"
                  name="email"
                  value={this.state.email}
                  onChange={this.onChange}
                />
                <br />
                <input
                  id="form_mdp"
                  type="password"
                  placeholder="Mot de passe"
                  name="password"
                  value={this.state.password}
                  onChange={this.onChange}
                />
                <br />
                <div id="this_button">
                  <button id="form_button" type="submit" >SE CONNECTER</button>
                  <p id="link_mdp" onClick={(event) => this.handleForget()}>Mot de passe oublié</p>
                  <p id="incorrect" style={this.state.style_incorrect}>Email incorrect</p>
                  <p id="incorrect2" style={this.state.style_incorrect2}>Mot de passe incorrect</p>
                  <p id="incorrect3" style={this.state.style_incorrect3}>Trop de tentatives, veuillez réessayer dans 5 minutes</p>
                </div>
              </form>
            </div>
          </div>
          <div id="myModal" className="modal" style={this.state.style_forget}>

            <div className="modal-content" >
              <p id="forget_title">Récupération de votre mot de passe</p>
              <form style={this.state.forget} className="form_connexion" >
                <input
                  id="form_email_forget"
                  type="email"
                  placeholder="Email professionnel"
                  name="email_forgot"
                  onChange={this.onChange}
                />
                <br />
                {/*<input
                  id="form_mdp_forget"
                  type="text"
                  placeholder="N° de compte client"
                  name="password"
                />*/}
                <br />
                <div id="this_button">
                  <button id="form_button_forget" onClick={(event) => this.handleForget2(event)}>Envoyer</button>
                </div>
              </form>
              <p style={{ fontSize: "1.2em" }}>{this.state.msg_forget}</p>
              {/*<p id="advice_forget">Vous devez saisir votre adresse email professionnelle et le N° de contrat qui commance par CXX suivis d'une série de chiffres</p>*/}
            </div>
          </div>
        </div>


      );
    }

  }
}
const Login = connect(
  null,
  mapDispatchToProps
)(Connected_login);

export default Login;
