import React, { Component } from 'react';
import '../css/Suivi.css';
import LEFT_SUIVI from './Left_suivi';
import { connect } from "react-redux";

const mapStateToProps = state => {
  return { suivi_page: state.suivi_page };
};

class Suivi extends Component {

  render() {
    return (
    	<div id="suivi">
		  	<LEFT_SUIVI/>
        {this.props.suivi_page}
		  </div>
      );
    }
  }
export default connect(mapStateToProps)(Suivi);


