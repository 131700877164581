import React, { Component } from 'react';
import '../css/Header.css';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { changeStatus, changeSuiviP, changeStyleRetour, changeStyleProfil, changeColorD, changeColorC, changeColorF, changeColorR, changeTitle, changeBoolP, changeStyleP, changeStyleR, changeStyleC, changeStyleF, changeStyleD} from "../actions/index";
import Accueil from "./Accueil";
import logo_proviso_white from '../img/logo_proviso_white.svg';
import logo_retour from '../img/logo_retour.png';
import logo_retour_vert from '../img/logo_retour_vert.png';
import Profile from './Profile';
import Suivi from './Suivi';
import Commande from './Commande';

const mapStateToProps = state => {
  return { title_header: state.title_header, style_d: state.style_d, profile_user:state.profile_user, style_retour:state.style_retour  };
};



function mapDispatchToProps(dispatch) {
    return {
      changeStatus: suivi_status => dispatch(changeStatus(suivi_status)),
      changeSuiviP: suivi_page => dispatch(changeSuiviP(suivi_page)),
      changeTitle: title_header => dispatch(changeTitle(title_header)),
      changeStyleD: style_d => dispatch(changeStyleD(style_d)),
      changeStyleC: style_c => dispatch(changeStyleC(style_c)),
      changeStyleProfil: style_profil => dispatch(changeStyleProfil(style_profil)),
      changeStyleP: style_p => dispatch(changeStyleP(style_p)),
      changeStyleF: style_f => dispatch(changeStyleF(style_f)),
      changeStyleR: style_r => dispatch(changeStyleR(style_r)),
      changeBoolP: boolp => dispatch(changeBoolP(boolp)),
      changeColorD: colord => dispatch(changeColorD(colord)),
      changeColorR: colorr => dispatch(changeColorR(colorr)),
      changeColorF: colorf => dispatch(changeColorF(colorf)),
      changeColorC: colorc => dispatch(changeColorC(colorc)),
      changeStyleRetour: style_retour => dispatch(changeStyleRetour(style_retour))
    };
}

class Header extends Component {

  constructor(props){
    super(props);
    this.state={
      style_panel:{display:"none"}
    }
    this.handleD = this.handleD.bind(this);
    this.handleD2 = this.handleD2.bind(this);
    this.handleRetour = this.handleRetour.bind(this);
    
  }

  handleLogout(event){
    localStorage.setItem('user',false);
    this.props.changeStatus(  <Accueil  key={"accueil"} /> );
    window.location.href=process.env.REACT_APP_ID_PROVISO;

  }

  handleRetour(event){

    this.props.changeStyleRetour({display:"none"});
  	this.props.changeTitle( 'Commandes' );
  	this.props.changeSuiviP( <Commande  test={true} key={"commande"} /> );
  }

  handleProfile(event){
    this.props.changeStatus( <Suivi component={Suivi}  key={"suivi"} /> );
    this.props.changeSuiviP( <Profile  key={"profile"} /> );
    this.props.changeStyleProfil({display:"block"});
    this.props.changeTitle( 'Profil' );
    this.props.changeStyleRetour({display:"none"});
    this.props.changeStyleP({backgroundColor:"#eeeeee"});
    this.props.changeStyleD({backgroundColor:"#f5f6f8"});
    this.props.changeStyleC({backgroundColor:"#f5f6f8"});
    this.props.changeStyleF({backgroundColor:"#f5f6f8"});
    this.props.changeStyleR({backgroundColor:"#f5f6f8"});
    this.props.changeBoolP(true);
    this.props.changeColorD(true);
    this.props.changeColorC(true);
    this.props.changeColorF(true);
    this.props.changeColorR(true);

  }

  handleL(event){
    this.props.changeStyleRetour({display:"none"});
    window.location.href = process.env.REACT_APP_ID_PROVISO+"/proviso_user";

  }
  handleD(){
    this.setState({style_panel:{display:"block"}});
  }

   handleD2(){
    this.setState({style_panel:{display:"none"}});
  }

  render() {
    return (
	    <div className="header">
		  	<div id="img_proviso"> <img id="logo_proviso_white" src={logo_proviso_white} onClick={(event) => this.handleL(event)} alt="logo Proviso"/></div>
		  	<p id="proviso_title"><img onClick={(event) => this.handleRetour(event)} id="logo_retour" style={this.props.style_retour} onMouseLeave={e => e.currentTarget.src = logo_retour} onMouseEnter={e => e.currentTarget.src = logo_retour_vert} src={logo_retour} alt="logo retour"/>{this.props.title_header}</p>
		  	<p id="proviso_nom" onMouseEnter={this.handleD} onMouseLeave={this.handleD2}>{this.props.profile_user.last_name.toUpperCase()+" "+this.props.profile_user.first_name}</p>
        <div className="panelC" style={this.state.style_panel} onMouseEnter={this.handleD} onMouseLeave={this.handleD2}>
                <div id="list_panel">
                   <p id="panel_p"  onClick={(event) => this.handleProfile(event)}>Mon profil</p>
                   <p id="panel_d"  onClick={(event) => this.handleLogout(event)}>Déconnexion</p>
                </div>
            </div>
		  </div>
      );
    }
  }
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
