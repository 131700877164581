import React, { Component } from 'react';
import '../css/profil.css';
import '../css/Login.css';
import '../css/Create_user.css';
import '../css/Accueil.css';
import { connect } from "react-redux";
import List_logo from './List_logo';
import logo_oeil from '../img/eye.png';
import logo_no_oeil from '../img/no-visibility.png';
import { register, sendImg, update_logo } from '../api/UserFunctions';

import { changeAllLogos, changeChoixAdm } from "../actions/index";

function mapDispatchToProps(dispatch) {
    return {
      changeAllLogos:all_logos => dispatch(changeAllLogos(all_logos)),
      changeChoixAdm: choix_adm => dispatch(changeChoixAdm(choix_adm))
    };
}

const bcrypt = require('bcryptjs');


const mapStateToProps = state => {
  return { all_logos: state.all_logos };
};

class Update_logo extends Component {


  constructor(props){
    super(props);
    this.state = {
      etps:this.props.admlogo[0].etps,
      selectedFile: null,
      value_res:""
    }
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.handlePass1 = this.handlePass1.bind(this);
    this.handlePass1P = this.handlePass1P.bind(this);
    this.handlePass2 = this.handlePass2.bind(this);
    this.handlePass2P = this.handlePass2P.bind(this);
    this.handleAnnul = this.handleAnnul.bind(this);
  }


  onSubmit(e) {
      e.preventDefault();

      if(true){

          const dataImg = {
                id: this.props.admlogo[0].id,
                etps: this.state.etps,
                selectedFile: this.state.selectedFile
              }
              if(this.state.selectedFile !== null){
                update_logo(dataImg).then(res => {
                let newArray = [];
                this.props.all_logos.forEach((userdata,index) => {
                  if(this.props.admlogo[0].id === userdata.id)
                  {
                    newArray.push(dataImg);
                  }
                  else{
                    newArray.push(userdata);
                  }
                });
                this.props.changeAllLogos(newArray);
                this.props.changeChoixAdm(<List_logo  key={"list_logo"} />);
              });
              }
              else{
                this.setState({value_res:"Fichier vide"});
              }
              

              
              this.setState({incorrect3:{display:"block"}});
              this.setState({incorrect1:{display:"none"}});
              this.setState({incorrect2:{display:"none"}});
      }
      else{
            this.setState({incorrect1:{display:"block"}});
            this.setState({incorrect3:{display:"none"}});
            this.setState({incorrect2:{diplay:"none"}});
      }
    }


  onChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  handlePass1(e){
    this.setState({pass1:'text'});
    this.setState({test1:false});
  }

  handlePass1P(e){
    this.setState({pass1:'password'});
    this.setState({test1:true});
  }


   handleAnnul(e){
    this.props.changeChoixAdm(<List_logo  key={"list_logo"} />);
  }

   handlePass2(e){
    this.setState({pass2:'text'});
    this.setState({test2:false});
  }

  handlePass2P(e){
    this.setState({pass2:'password'});
    this.setState({test2:true});
  }

  onFileChange = event => { 
     
      // Update the state 
      this.setState({ selectedFile: event.target.files[0] }); 
     
    }; 



  render() {
    return (

    	<div className="create_user">
        <div id="block">
          <div id='img_p'>
          <form className="form_profile" id="profile2" onSubmit={this.onSubmit}>
                <img id="logo_sipperec2" src={process.env.REACT_APP_API+'/users/display/'+this.props.admlogo[0].img} alt="Logo etps"/>
                 <br/>
                <input
                  id="form_profile_mdp22"
                  type="text"
                  name="etps"
                  placeholder="Entreprise"
                  value={this.state.etps}
                  onChange={this.onChange}
                />
                <br/>
                <input id ="form_file" type="file" onChange={this.onFileChange} />
                <br />
                <button id="form_button" type="submit">Modifier</button><button id="form_button" onClick={(event) => this.handleAnnul(event)}>Annuler</button>
                <p>{this.state.value_res.toString()}</p>
              </form>
          </div>
        </div>
		  </div>
      );
    }
  }
export default connect(mapStateToProps, mapDispatchToProps)(Update_logo);


