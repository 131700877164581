import React, { Component } from 'react';
import logo from '../img/logo_cfi.png';
import logo_proviso from '../img/cfi_logo_proviso_home.svg';
import { update_userf, check } from '../api/UserFunctions';
import '../css/Login.css';
import Auth from '../auth/Auth';
import { connect } from "react-redux";
import { decodeToken } from "react-jwt";
import { changeProfile, changeCommande, changeDevis, changeAuth} from "../actions/index";
const bcrypt = require('bcryptjs');
function mapDispatchToProps(dispatch) {
    return {
      changeProfile:profile_user => dispatch(changeProfile(profile_user)),
      changeCommande:commande_user => dispatch(changeCommande(commande_user)),
      changeDevis:devis_user => dispatch(changeDevis(devis_user)),
      changeAuth:auth => dispatch(changeAuth(auth))
    };
}

const mapStateToProps = state => {
    return { profile_user: state.profile_user };
  };

class Connected_forgot extends Component {
  _isMounted = false;

  constructor(props){
    super(props);
    this.state = {
      email: '',
      password: '',
      ok:0,
      incorrect1:{display:"none"},
      incorrect2:{display:"none"},
      incorrect3:{display:"none"},
      page:false,
      errors: {},
      renderP:false,
      res:{},
      mdp1:'',
      mdp2:'',
    }


    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.handleConnexion=this.handleConnexion.bind(this);
    this.logout=this.logout.bind(this);
  }


  componentDidMount() {
    this._isMounted = true;
    check(this.props.match.params.id).then(res => {
        if(res.localeCompare("error")==0){
            this.setState({renderP:false});
        }
        else{
            this.setState({renderP:true, res:res})
        }
    });
  }

   componentWillUnmount() {
    this._isMounted = false;
  }

  handleForget(){
    this.setState({style_forget:{display:"block"}});
    this.setState({page:true});
  }
  handlePage(event){
    const target = event.target;
    if (target.id !== 'modal') {
    return ;
    }
    this.setState({style_forget:{display:"none"}});
    this.setState({page:false});
  }

  handleConnexion(){
    
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }
  onSubmit(e) {
    e.preventDefault();
    if(true){
        if(this.state.mdp2.length>=8 && this.state.mdp1.length>=8){
            if(this.state.mdp1.localeCompare(this.state.mdp2)===0){
                const user = {
                    email: decodeToken(this.props.match.params.id),
                    password: this.state.mdp2
                  }
                  
                  update_userf(user).then(res => {
                  });
                  this.setState({incorrect3:{display:"block"}});
                  this.setState({incorrect1:{display:"none"}});
                  this.setState({incorrect2:{display:"none"}});
                  setTimeout(() => {
                    window.location.href = process.env.REACT_APP_ID_PROVISO+"/";
                  }, 5000);
            }
            else{
                this.setState({incorrect3:{display:"none"}});
            this.setState({incorrect1:{display:"block"}});
            this.setState({incorrect2:{display:"none"}});
            }

          
        }
        else{
            this.setState({incorrect3:{display:"none"}});
            this.setState({incorrect1:{display:"none"}});
            this.setState({incorrect2:{display:"block"}});
        }
      }
      




  }


  login(){
  Auth.authenticate();
  }
  logout(){
  Auth.signout();
  }

  updateState() {
        this.setState({ error_msg: true });
    }


    render() {

        

        var decoded = decodeToken(this.props.match.params.id);
        if(this.state.renderP)
        {
            return (
                <div className="modal-content" >
                      <p id="forget_title">Réinitialisation de votre mot de passe</p>
                      <form className="form_connexion" onSubmit={this.onSubmit}>
                        <input
                          id="form_email_forget"
                          type="password"
                          placeholder="Nouveau mot de passe"
                          name="mdp1"
                          onChange={this.onChange}
                          />
                        <input
                          id="form_email_forget2"
                          type="password"
                          placeholder="Confirmer le mot de passe"
                          name="mdp2"
                          onChange={this.onChange}
                          />
                        <br/>
                        <div id="this_button">
                           <button id="form_button_forget" type="submit">Envoyer</button>
                        </div>
                        <p id="m_mdp" style={this.state.incorrect1}>Les mots de passe ne sont pas identiques</p>
                        <p id="cons" style={this.state.incorrect2}>Utilisez au moins huit caractères</p>
                        <p id="p_mdp" style={this.state.incorrect3}>Mot de passe changé</p>
                      </form>
                      {/*<p id="advice_forget">Vous devez saisir votre adresse email professionnelle et le N° de contrat qui commance par CXX suivis d'une série de chiffres</p>*/}
                    </div>
        
        
                );
        }
        else{
            return null;
        }
        
                }
        
  }
const Forgot = connect(
  mapStateToProps,
  mapDispatchToProps
)(Connected_forgot);
export default Forgot;
