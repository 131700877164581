import React, { Component } from 'react';
import '../css/profil.css';
import '../css/Login.css';
import '../css/Create_user.css';
import { connect } from "react-redux";
import logo_oeil from '../img/eye.png';
import logo_no_oeil from '../img/no-visibility.png';
import { register, sendImg } from '../api/UserFunctions';

const bcrypt = require('bcryptjs');


const mapStateToProps = state => {
  return { profile_user: state.profile_user, all_logos:state.all_logos };
};

class Create_user extends Component {


  constructor(props){
    super(props);
    this.state = {
      value_res:'',
      test1:true,
      test2:true,
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      groupe: '',
      num_client: '',
      tel: '',
      etps: '',
      site_web: '',
      adr: '',
      postal: '',
      ville: '',
      pays: '',
      pays_num: '',
      user_detail:'oui',
      etps:'',
      selectedFile: null,
      contract_date: null
    }
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.handlePass1 = this.handlePass1.bind(this);
    this.handlePass1P = this.handlePass1P.bind(this);
    this.handlePass2 = this.handlePass2.bind(this);
    this.handlePass2P = this.handlePass2P.bind(this);
    this.renderTable = this.renderTable.bind(this);
  }


  onSubmit(e) {
      e.preventDefault();

      if(this.state.email.localeCompare("")!==0 && this.state.password.localeCompare("")!==0){

          const user = {
                first_name: this.state.first_name,
                last_name: this.state.last_name,
                email: this.state.email,
                password: this.state.password,
                groupe: this.state.groupe,
                num_client: this.state.num_client,
                tel: this.state.tel,
                etps: this.state.etps,
                site_web: this.state.site_web,
                adr: this.state.adr,
                postal: this.state.postal,
                ville: this.state.ville,
                pays: this.state.pays,
                pays_num: this.state.pays_num,
                contract_date: this.state.contract_date,
                user_detail:this.state.user_detail
              }

              
              register(user).then(res => {
                
                var keys = Object.keys(res);
                if(keys[0].localeCompare("error")==0){
                  this.setState({value_res:"Erreur, l'email inscrit existe déjà"});
                }
                else{
                  
                  this.setState({value_res:"L'utilisateur "+this.state.email+" a bien été enregistré"});
                }
              });

      }
      else{
        this.setState({value_res:"L'email et le mot de passe ne peuvent être vides"});
      }
    }


  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  renderTable() {
    return this.props.all_logos.map((logo,index) => {

      return(
        <option id={index} value={logo.etps}>{logo.etps}</option>
      )

    });

  }

  handlePass1(e){
    this.setState({pass1:'text'});
    this.setState({test1:false});
  }

  handlePass1P(e){
    this.setState({pass1:'password'});
    this.setState({test1:true});
  }

   handlePass2(e){
    this.setState({pass2:'text'});
    this.setState({test2:false});
  }

  handlePass2P(e){
    this.setState({pass2:'password'});
    this.setState({test2:true});
  }

  onFileChange = event => { 
     
      // Update the state 
      this.setState({ selectedFile: event.target.files[0] }); 
     
    }; 



  render() {
    return (

    	<div className="create_user">
        <div id="block1">
          <div id='img_p'>
          <form className="form_profile" id="profile2" onSubmit={this.onSubmit}>
                <input
                  id="form_profile_mdp22"
                  type="text"
                  name="first_name"
                  placeholder="Prénom"
                  value={this.state.first_name}
                  onChange={this.onChange}
                  />
                  <br/>
                <input
                  id="form_profile_mdp22"
                  type="text"
                  name="last_name"
                  placeholder="Nom de famille"
                  value={this.state.last_name}
                  onChange={this.onChange}
                />
                <br/>
                <input
                  id="form_profile_mdp22"
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={this.state.email}
                  onChange={this.onChange}
                />
                <br/>
                <input
                  id="form_profile_mdp22"
                  type="password"
                  name="password"
                  placeholder="Mot de passe"
                  value={this.state.password}
                  onChange={this.onChange}
                />
                <br/>
                <input
                  id="form_profile_mdp22"
                  type="text"
                  name="groupe"
                  placeholder="Groupe"
                  value={this.state.groupe}
                  onChange={this.onChange}
                />
                <br/>
                <input
                  id="form_profile_mdp22"
                  type="text"
                  name="num_client"
                  placeholder="Numéro client"
                  value={this.state.num_client}
                  onChange={this.onChange}
                />
                <br/>
                <input
                  id="form_profile_mdp22"
                  type="text"
                  name="tel"
                  placeholder="Téléphone"
                  value={this.state.tel}
                  onChange={this.onChange}
                />
                <br/>
                <input
                  id="form_profile_mdp22"
                  type="text"
                  name="site_web"
                  placeholder="Site web de l'entreprise"
                  value={this.state.site_web}
                  onChange={this.onChange}
                />
                <br/>
                <input
                  id="form_profile_mdp22"
                  type="text"
                  name="adr"
                  placeholder="Adresse de l'entreprise"
                  value={this.state.adr}
                  onChange={this.onChange}
                />
                <br/>
                <input
                  id="form_profile_mdp22"
                  type="text"
                  name="postal"
                  placeholder="Code postal de l'entreprise"
                  value={this.state.postal}
                  onChange={this.onChange}
                />
                <br/>
                <input
                  id="form_profile_mdp22"
                  type="text"
                  name="ville"
                  placeholder="Ville de l'entreprise"
                  value={this.state.ville}
                  onChange={this.onChange}
                />
                <br/>
                <input
                  id="form_profile_mdp22"
                  type="text"
                  name="pays"
                  placeholder="Pays de l'entreprise"
                  value={this.state.pays}
                  onChange={this.onChange}
                />
                <br/>
                <input
                  id="form_profile_mdp22"
                  type="text"
                  name="pays_num"
                  placeholder="Pays de l'utilisateur"
                  value={this.state.pays_num}
                  onChange={this.onChange}
                />
                 <br/>
                 <p className="p_info">Entreprise :&nbsp;</p>
                <select name="etps" value={this.state.etps}  onChange={this.onChange}>
                  <option value=""></option>
                  {this.renderTable()}
                </select>
                <br/>
                 <p className="p_info">Date du contrat :</p>
                <input
                  id="form_profile_mdp22"
                  type="date"
                  name="contract_date"
                  value={this.state.contract_date}
                  onChange={this.onChange}
                />
                <br />
                <p className="p_info">Accès au détails :&nbsp;</p>
                <select name="user_detail" value={this.state.user_detail}  onChange={this.onChange}>
                  <option value="non">Non</option>
                  <option value="oui">Oui</option>
                </select>
                <br />
                <button id="form_button" type="submit">Envoyer</button>
                <p>{this.state.value_res.toString()}</p>
              </form>
          </div>
        </div>
		  </div>
      );
    }
  }
export default connect(mapStateToProps)(Create_user);


