import React, { Component } from 'react';
import '../css/App.css';
import Create_user from './Create_user';
import Left_admin from './Left_admin';
import { connect } from "react-redux";
import {  findallusers, findalllogos } from '../api/UserFunctions';
import { changeAllUsers, changeAllLogos } from "../actions/index";

const mapStateToProps = state => {
  return { choix_adm: state.choix_adm};
};

function mapDispatchToProps(dispatch) {
    return {
      changeAllUsers:all_users => dispatch(changeAllUsers(all_users)),
      changeAllLogos:all_logos => dispatch(changeAllLogos(all_logos))
    };
}


class Choix_adm extends Component {
  constructor(props){
    super(props);
  }

  componentDidMount(){
    findallusers().then(res => {
      this.props.changeAllUsers(res);
      });

    findalllogos().then(res => {
      this.props.changeAllLogos(res);
      });

      
    }

  render() {
    return (
      <div className="App" >
        <Left_admin/>
        {this.props.choix_adm}
      </div>

      
    );
  }
}
export default connect(mapStateToProps,mapDispatchToProps)(Choix_adm);