import React, { Component } from 'react';
import '../css/Left_suivi.css';
import { connect } from "react-redux";
import { changeSuiviP, changeTitle, changeStyleRetour, changeColorD, changeColorR, changeColorF, changeStyleF, changeStyleR, changeFT, changeRT, changeColorC, changeStyleProfil, changeStyleP, changeBoolP, changeStyleD, changeStyleC, changeCT, changeDT} from "../actions/index";
import Commande from "./Commande";
import Devis from "./Devis";
import Facture from "./Facture";
import Reporting from "./Reporting";
import Misajour from "./Misajour";
import logo2 from '../img/icon_D_color.png';
import logoc2 from '../img/icon_C_color.png';
import logof from '../img/icon_F.png';
import logor from '../img/icon_R.png';
import logocfi from '../img/logo_cfi_grey_p.svg';
import logoupdate from '../img/update.png';


const mapStateToProps = state => {
  return { suivi_status: state.suivi_status, boolp:state.boolp,style_d: state.style_d, style_r: state.style_r, style_c: state.style_c, style_f: state.style_f, style_profil:state.style_profil, style_p:state.style_p, ct:state.ct, rt:state.rt, dt:state.dt, colorf:state.colorf, ft:state.ft, colord: state.colord, colorr: state.colorr, colorc: state.colorc, colorp: state.colorp };
};


function mapDispatchToProps(dispatch) {
  	return {
  		changeTitle: title_header => dispatch(changeTitle(title_header)),
      changeSuiviP: suivi_page => dispatch(changeSuiviP(suivi_page)),
      changeStyleP: style_p => dispatch(changeStyleP(style_p)),
      changeStyleProfil: style_profil => dispatch(changeStyleProfil(style_profil)),
      changeStyleD: style_d => dispatch(changeStyleD(style_d)),
      changeStyleC: style_c => dispatch(changeStyleC(style_c)),
      changeStyleF: style_f => dispatch(changeStyleF(style_f)),
      changeStyleR: style_r => dispatch(changeStyleR(style_r)),
      changeDT: dt => dispatch(changeDT(dt)),
      changeCT: ct => dispatch(changeCT(ct)),
      changeFT: ft => dispatch(changeFT(ft)),
      changeRT: rt => dispatch(changeRT(rt)),
      changeBoolP: boolp => dispatch(changeBoolP(boolp)),
      changeColorD: colord => dispatch(changeColorD(colord)),
      changeColorR: colorr => dispatch(changeColorR(colorr)),
      changeColorC: colorc => dispatch(changeColorC(colorc)),
      changeColorF: colorf => dispatch(changeColorF(colorf)),
      changeStyleRetour: style_retour => dispatch(changeStyleRetour(style_retour))


  	};
}


class Left_suivi extends Component {

  constructor(props){
    super(props);

    this.state = {
    	d:this.props.style_d,
    	c:this.props.style_c
    	};

    this.handleD = this.handleD.bind(this);
    this.handleC = this.handleC.bind(this);
    this.toggleHover = this.toggleHover.bind(this);
    this.toggleHover2 = this.toggleHover2.bind(this);
    this.toggleHoverL = this.toggleHoverL.bind(this);
    this.toggleHover2L = this.toggleHover2L.bind(this);
    this.toggleHoverp = this.toggleHoverp.bind(this);
    this.toggleHoverp2 = this.toggleHoverp2.bind(this);
    this.toggleHover3 = this.toggleHover3.bind(this);
    this.toggleHover3L = this.toggleHover3L.bind(this);
    this.toggleHover4 = this.toggleHover4.bind(this);
    this.toggleHover4L = this.toggleHover4L.bind(this);
  }

  toggleHover() {
    this.props.changeStyleD({backgroundColor:"#eeeeee"});
  }

  toggleHoverp() {
    this.props.changeStyleP({backgroundColor:"#eeeeee"});
  }

  toggleHoverp2() {
    this.props.changeStyleP({backgroundColor:"#eeeeee"});
  }

  toggleHover2() {
    this.props.changeStyleC({backgroundColor:"#eeeeee"});
  }

    toggleHoverL() {
    if(this.props.rt || this.props.ft || this.props.ct || this.props.boolp){
      this.props.changeStyleD({backgroundColor:"#f5f6f8"});
    }
    
   
  }

  toggleHover2L() {
    if(this.props.rt || this.props.ft || this.props.dt || this.props.boolp){
      this.props.changeStyleC({backgroundColor:"#f5f6f8"});
    }  
  }


  toggleHover3() {
    this.props.changeStyleF({backgroundColor:"#eeeeee"});
  }

  toggleHover3L() {
    if(this.props.rt || this.props.dt || this.props.ct || this.props.boolp){
      this.props.changeStyleF({backgroundColor:"#f5f6f8"});
    }  
  }

   toggleHover4() {
    this.props.changeStyleR({backgroundColor:"#eeeeee"});
  }

  toggleHover4L() {
    if(this.props.ft ||this.props.dt || this.props.ct || this.props.boolp){
      this.props.changeStyleR({backgroundColor:"#f5f6f8"});
    }  
  }


  handleF(event){
    this.props.changeStyleRetour({display:"none"});
    this.props.changeFT(true);
    this.props.changeCT(false);
    this.props.changeDT(false);
    this.props.changeRT(false);
    this.props.changeTitle( 'Factures / Avoirs non lettrés' );
    this.props.changeSuiviP( <Facture  key={"facture"} /> );
    this.props.changeStyleProfil({display:"none"});
    this.props.changeStyleF({backgroundColor:"#eeeeee"});
    this.props.changeStyleC({backgroundColor:"#f5f6f8"});
    this.props.changeStyleD({backgroundColor:"#f5f6f8"});
    this.props.changeStyleR({backgroundColor:"#f5f6f8"});
    this.props.changeBoolP(false);
    this.props.changeColorF(false);
    this.props.changeColorC(true);
    this.props.changeColorD(true);
    this.props.changeColorR(true);

  }

  handleD(event){
    this.props.changeStyleRetour({display:"none"});
    this.props.changeStyleRetour({display:"none"});
    this.props.changeDT(true);
    this.props.changeCT(false);
    this.props.changeFT(false);
    this.props.changeRT(false);
  	this.props.changeTitle( 'Devis' );
  	this.props.changeSuiviP( <Devis  key={"devis"} /> );
    this.props.changeStyleProfil({display:"none"});
    this.props.changeStyleD({backgroundColor:"#eeeeee"});
    this.props.changeStyleC({backgroundColor:"#f5f6f8"});
    this.props.changeStyleF({backgroundColor:"#f5f6f8"});
    this.props.changeStyleR({backgroundColor:"#f5f6f8"});
    this.props.changeBoolP(false);
    this.props.changeColorD(false);
    this.props.changeColorC(true);
    this.props.changeColorF(true);
    this.props.changeColorR(true);

  }

  handleC(event){
    this.props.changeStyleRetour({display:"none"});
    this.props.changeCT(true);
    this.props.changeDT(false);
    this.props.changeFT(false);
    this.props.changeRT(false);
  	this.props.changeTitle( 'Commandes' );
  	this.props.changeSuiviP( <Commande  key={"commande"} /> );
    this.props.changeStyleProfil({display:"none"});
    this.props.changeStyleC({backgroundColor:"#eeeeee"});
    this.props.changeStyleD({backgroundColor:"#f5f6f8"});
    this.props.changeStyleF({backgroundColor:"#f5f6f8"});
    this.props.changeStyleR({backgroundColor:"#f5f6f8"});
    this.props.changeBoolP(false);
    this.props.changeColorC(false);
    this.props.changeColorR(true);
    this.props.changeColorD(true);
    this.props.changeColorF(true);
  }

  handleR(event){
    this.props.changeStyleRetour({display:"none"});
    this.props.changeRT(true);
    this.props.changeCT(false);
    this.props.changeDT(false);
    this.props.changeFT(false);
    this.props.changeTitle( 'Reporting' );
    this.props.changeSuiviP( <Reporting  key={"reporting"} /> );
    this.props.changeStyleProfil({display:"none"});
    this.props.changeStyleR({backgroundColor:"#eeeeee"});
    this.props.changeStyleC({backgroundColor:"#f5f6f8"});
    this.props.changeStyleD({backgroundColor:"#f5f6f8"});
    this.props.changeStyleF({backgroundColor:"#f5f6f8"});
    this.props.changeBoolP(false);
    this.props.changeColorR(false);
    this.props.changeColorC(true);
    this.props.changeColorD(true);
    this.props.changeColorF(true);
  }

  handleMAJ(event){
    this.props.changeStyleRetour({display:"none"});
    this.props.changeRT(false);
    this.props.changeCT(false);
    this.props.changeDT(false);
    this.props.changeFT(false);
    this.props.changeTitle( 'Mises-à-jour' );
    this.props.changeSuiviP( <Misajour  key={"misajour"} /> );
    this.props.changeStyleProfil({display:"none"});
    this.props.changeStyleR({backgroundColor:"#f5f6f8"});
    this.props.changeStyleC({backgroundColor:"#f5f6f8"});
    this.props.changeStyleD({backgroundColor:"#f5f6f8"});
    this.props.changeStyleF({backgroundColor:"#f5f6f8"});
    this.props.changeBoolP(true);
    this.props.changeColorR(true);
    this.props.changeColorC(true);
    this.props.changeColorD(true);
    this.props.changeColorF(true);
  }

  render() {
    return (
	    <div id="left_suivi">
        <p id="profil_p"  style={Object.assign({}, this.props.style_profil, this.props.style_p)} onMouseEnter={this.toggleHoverp} onMouseLeave={this.toggleHoverp2}>Profil</p>
    		<p id="devis_p"  style={Object.assign({}, this.props.style_d, this.props.colord)} onMouseEnter={this.toggleHover} onMouseLeave={this.toggleHoverL} onClick={(event) => this.handleD(event)}>{<img id="logo_devis" src={logo2} alt="logo devis actif"/>}
Devis</p>
    		<p id="commande_p"  style={this.props.style_c} onMouseEnter={this.toggleHover2} onMouseLeave={this.toggleHover2L} onClick={(event) => this.handleC(event)}>{<img id="logo_devis" src={logoc2} alt="logo commande active"/>}Commandes</p>
        <p id="facture_p"  style={Object.assign({}, this.props.style_f, this.props.colorf) } onClick={(event) => this.handleF(event)} onMouseEnter={this.toggleHover3} onMouseLeave={this.toggleHover3L}><img id="logo_devis" src={logof} alt="logo facture inactive"/>Factures</p>
        {/*<p id="reporting_p"   style={Object.assign({}, this.props.style_r, this.props.colorr) } onClick={(event) => this.handleR(event)} onMouseEnter={this.toggleHover4} onMouseLeave={this.toggleHover4L} ><img id="logo_devis" src={logor} alt="logo reporting inactif"/>Reporting</p>*/}
        <p id="ver"><img id="logo_update" src={logoupdate} alt="mis à jour" onClick={(event) => this.handleMAJ(event)}/></p>
	<footer id="ver"><p id="realised"><img id="logo_cfi_grey" src={logocfi} alt="logo cfi"/>2023 - Version 1.03.23.1</p></footer>
		  </div>
      );
    }

    
  }
export default connect(mapStateToProps,mapDispatchToProps)(Left_suivi);
