import React, { Component } from 'react';
import '../css/Chargement.css';
import charging from "../img/charging.gif";
import { connect } from "react-redux";
import { changeStatus, changeSuiviP, changeTitle, changeStyleD, changeStyleC, changeProfile, changeCommande, changeDevis, changeCharge } from "../actions/index";


function mapDispatchToProps(dispatch) {
  	return {
    	changeStatus: suivi_status => dispatch(changeStatus(suivi_status)),
      changeTitle: title_header => dispatch(changeTitle(title_header)),
      changeSuiviP: suivi_page => dispatch(changeSuiviP(suivi_page)),
      changeStyleD: style_d => dispatch(changeStyleD(style_d)),
      changeStyleC: style_c => dispatch(changeStyleC(style_c)),
      changeProfile:profile_user => dispatch(changeProfile(profile_user)),
      changeCommande:commande_user => dispatch(changeCommande(commande_user)),
      changeDevis:devis_user => dispatch(changeDevis(devis_user)),
      changeCharge:charge => dispatch(changeCharge(charge))
  	};
}



class Connected_chargement extends Component {



	constructor(props){
    	super(props);
      this.state={
        isLoaded:false
      }
      
  	}

    render() {
        return (
        <div id="console">
          <div id="chargement">
            <img id="charge" src={charging} alt="chargement"/> 
          </div>
        </div>
        );
      
      
  
}
}

const Chargement = connect(
  null,
  mapDispatchToProps
)(Connected_chargement);

export default Chargement;