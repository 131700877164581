import React, { Component } from 'react';
import '../css/profil.css';
import { connect } from "react-redux";
import logo_oeil from '../img/eye.png';
import logo_no_oeil from '../img/no-visibility.png';
import { update_user } from '../api/UserFunctions';

const bcrypt = require('bcryptjs');


const mapStateToProps = state => {
  return { profile_user: state.profile_user };
};

class Profile extends Component {


  constructor(props){
    super(props);
    this.state = {
      mdp1:'',
      mdp2:'',
      incorrect1:{display:"none"},
      incorrect2:{display:"none"},
      incorrect3:{display:"none"},
      pass1:'password',
      pass2:'password',
      test1:true,
      test2:true
    }
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.handlePass1 = this.handlePass1.bind(this);
    this.handlePass1P = this.handlePass1P.bind(this);
    this.handlePass2 = this.handlePass2.bind(this);
    this.handlePass2P = this.handlePass2P.bind(this);
  }


  onSubmit(e) {
      e.preventDefault();

      if(bcrypt.compareSync(this.state.mdp1, this.props.profile_user.password)){
        if(this.state.mdp2.length>=8){

          const user = {
                id:this.props.profile_user.id,
                first_name: this.props.profile_user.first_name,
                last_name: this.props.profile_user.last_name,
                email: this.props.profile_user.email,
                password: this.state.mdp2,
                groupe: this.props.profile_user.groupe,
                num_client: this.props.profile_user.num_client,
                tel: this.props.profile_user.tel,
                etps: this.props.profile_user.etps,
                site_web: this.props.profile_user.site_web,
                adr: this.props.profile_user.adr,
                postal: this.props.profile_user.postal,
                ville: this.props.profile_user.ville,
                pays: this.props.profile_user.pays,
                pays_num: this.props.profile_user.pays_num
              }
              
              update_user(user).then(res => {
              });
              this.setState({incorrect3:{display:"block"}});
              this.setState({incorrect1:{display:"none"}});
              this.setState({incorrect2:{display:"none"}});
        }
        else{
          this.setState({incorrect3:{display:"none"}});
              this.setState({incorrect1:{display:"none"}});
              this.setState({incorrect2:{display:"block"}});
        }
      }
      else{
            this.setState({incorrect1:{display:"block"}});
            this.setState({incorrect3:{display:"none"}});
            this.setState({incorrect2:{diplay:"none"}});
      }
    }


  onChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  handlePass1(e){
    this.setState({pass1:'text'});
    this.setState({test1:false});
  }

  handlePass1P(e){
    this.setState({pass1:'password'});
    this.setState({test1:true});
  }

   handlePass2(e){
    this.setState({pass2:'text'});
    this.setState({test2:false});
  }

  handlePass2P(e){
    this.setState({pass2:'password'});
    this.setState({test2:true});
  }



  render() {
    return (

    	<div id="console">
        <div id="block">
          <div id='img_p'>
          <div className="form_profile" id="profile">
            <p id="info-p">Informations personnelles</p>
            <p id='etps-p'>Entreprise</p>
          </div>
          <form className="form_profile" id="profile2" onSubmit={this.onSubmit}>
                <p className='nom' id="pr">Email</p>
                <p
                  id="form_profile_email">{this.props.profile_user.email}</p>
                <p className='nom' id="pr1">Prénom</p><p className="nom" id="pr2">Nom</p>
                <br/>
                <p
                  className='nom'
                  id="form_profile_name"
                > {this.props.profile_user.first_name}</p>
                <p
                  className='nom'
                  id="form_profile_lname"
                >{this.props.profile_user.last_name}</p>
                <br/>
                <p className='nom' id="pr3">Pays</p><p className="nom" id="pr4">Numéro de téléphone</p>
                <br/>
                <p
                  className="nom"
                  id="form_profile_pays_num"
                >{this.props.profile_user.pays_num}</p>
                <p
                  className="nom"
                  id="form_profile_num"
                 >{this.props.profile_user.tel}</p>
                <br/>
                <br/>
                <br/>
                <p className="nom" id="pr5">Entreprise / Organisation</p><p className="nom" id="pr6">Site web</p>
                <br/>
                <p
                  className="nom"
                  id="form_profile_etps"
                >{this.props.profile_user.etps.toUpperCase()}</p>
                <p
                  className="nom"
                  id="form_profile_site"
                >{this.props.profile_user.site_web}</p>
                <br/>
                <p className='nom' id="pra">Adresse</p>
                <p
                  id="form_profile_adr"
                >{this.props.profile_user.adr}</p>
                <p className='nom' id="pr7">Code postal</p><p className="nom" id="pr8">Ville</p>
                <br/>
                <p
                  className='nom'
                  id="form_profile_postal"
                >{this.props.profile_user.postal}</p>
                <p
                  className='nom'
                  id="form_profile_ville"
                >{this.props.profile_user.ville}</p>
                <br/>
                <p id="prp">Pays</p>
                <p
                  id="form_profile_pays"
                >{this.props.profile_user.pays}</p>
              </form>

              <div className="form_profile" id="profile3">
                <p id="pmdp">Mot de passe</p>
              </div>

              <form className="form_profile" id="profile4" onSubmit={this.onSubmit}>
                <p id="pr9">Mot de passe actuel</p>
                 {this.state.test1 ? <img id="img_mdp" onClick={(event) => this.handlePass1(event)} src={logo_oeil} alt="oeil"/>: <img id="img_mdpp" onClick={(event) => this.handlePass1P(event)} src={logo_no_oeil} alt="oeil barré"/>}

                <input
                  id="form_profile_mdp"
                  type={this.state.pass1}
                  name="mdp1"
                  value={this.state.mdp1}
                  onChange={this.onChange}
                  />
                  <p id="m_mdp" style={this.state.incorrect1}>Mot de passe invalide</p>
                <br/>
                <br/>
                <p id="pr10">Nouveau mot de passe</p>
                 {this.state.test2 ? <img id="img_mdp2" onClick={(event) => this.handlePass2(event)} src={logo_oeil} alt="oeil"/>: <img id="img_mdp2p" onClick={(event) => this.handlePass2P(event)} src={logo_no_oeil} alt="oeil barré"/>}
                 
                <input
                  id="form_profile_mdp2"
                  type={this.state.pass2}
                  name="mdp2"
                  value={this.state.mdp2}
                  onChange={this.onChange}
                />
                <br/>
                <br/>
                <p id="cons" style={this.state.incorrect2}>Utilisez au moins huit caractères</p>
                <p id="p_mdp" style={this.state.incorrect3}>Mot de passe changé</p>
                <div id="this_button">
                   <button id="form_profile_btn" type="submit" onClick={(event) => this.redirectToHome}>Mettre à jour mon mot de passe</button>                </div>
              </form>
          </div>
        </div>
		  </div>
      );
    }
  }
export default connect(mapStateToProps)(Profile);


