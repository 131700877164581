import { CHANGE_PROFILE,CHANGE_STATUS_SUIVI } from "../actions/action-types";

export function testMiddleware({ dispatch }) {
  return function(next) {
    return function(action) {
      if (action.type === CHANGE_PROFILE) {
          action.payload.last_name = action.payload.last_name.toUpperCase();

      }
      if (action.type === CHANGE_STATUS_SUIVI) {

      }
      return next(action);
    };
  };
}