import React, { Component } from 'react';
import logo from '../img/logo_cfi.png';
import logo_proviso from '../img/cfi_logo_proviso_home.svg';
import { login } from '../api/UserFunctions';
import '../css/Log_adm.css';
import '../css/App.css';
import '../css/responsive.css';
import Auth from '../auth/Auth';
import { connect } from "react-redux";
import { changeProfile, changeAdmPage, changeCommande, changeDevis, changeAuth, changeDT, changeCT, changeFT, changeBoolP, changeStyleProfil, changeStyleD, changeStyleC, changeStyleF, changeColorD, changeColorC, changeColorF} from "../actions/index";
import {  profile } from '../api/UserFunctions';
import Choix_adm from './Choix_adm'

function mapDispatchToProps(dispatch) {
    return {
      changeProfile:profile_user => dispatch(changeProfile(profile_user)),
      changeCommande:commande_user => dispatch(changeCommande(commande_user)),
      changeDevis:devis_user => dispatch(changeDevis(devis_user)),
      changeAuth:auth => dispatch(changeAuth(auth)),
      changeAdmPage:adm_page => dispatch(changeAdmPage(adm_page)),
      changeStyleProfil: style_profil => dispatch(changeStyleProfil(style_profil)),
      changeStyleD: style_d => dispatch(changeStyleD(style_d)),
      changeStyleC: style_c => dispatch(changeStyleC(style_c)),
      changeStyleF: style_f => dispatch(changeStyleF(style_f)),
      changeDT: dt => dispatch(changeDT(dt)),
      changeCT: ct => dispatch(changeCT(ct)),
      changeFT: ft => dispatch(changeFT(ft)),
      changeBoolP: boolp => dispatch(changeBoolP(boolp)),
      changeColorD: colord => dispatch(changeColorD(colord)),
      changeColorC: colorc => dispatch(changeColorC(colorc)),
      changeColorF: colorf => dispatch(changeColorF(colorf))
    };
}

class Connected_log_adm extends Component {
  _isMounted = false;

  constructor(props){
    super(props);
    this.state = {
      email: '',
      password: '',
      ok:0,
      style_forget:{display:"none"},
      style_incorrect:{display:"none"},
      style_incorrect2:{display:"none"},
      style_incorrect3:{display:"none"},
      style_incorrect4:{display:"none"},
      page:false,
      errors: {}
    }


    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.handleConnexion=this.handleConnexion.bind(this);
    this.logout=this.logout.bind(this);
  }


  componentDidMount() {
    this._isMounted = true;
  }

   componentWillUnmount() {
    this._isMounted = false;
  }

  handleForget(){
    this.setState({style_forget:{display:"block"}});
    this.setState({page:true});
  }
  handlePage(event){
    const target = event.target;
    if (target.id !== 'modal') {
    return ;
    }
    this.setState({style_forget:{display:"none"}});
    this.setState({page:false});
  }

  handleConnexion(){
    
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }
  onSubmit(e) {
    this.setState({essai:true});
    e.preventDefault();
    const user = {
      email: this.state.email,
      password: this.state.password,
      ok:this.state.ok
    }
    
    login(user).then(res => {

      this._isMounted = true;

      if (res) {
        profile().then(res => { 
          if(res.groupe.localeCompare("admin_cfi")==0){
            this.props.changeAdmPage(  <Choix_adm  key={"choix_adm"} /> );
            this.props.changeDT(true);
            this.props.changeCT(false);
            this.props.changeFT(false);
            this.props.changeBoolP(false);
            this.props.changeStyleProfil({display:"none"});
            this.props.changeStyleD({backgroundColor:"#eeeeee"});
            this.props.changeStyleC({backgroundColor:"#f5f6f8"});
            this.props.changeStyleF({backgroundColor:"#f5f6f8"});
            this.props.changeColorD(false);
            this.props.changeColorC(true);
            this.props.changeColorF(true);
          }
          else{
            this.setState({style_incorrect4:{display:"block"}});
            this.setState({style_incorrect:{display:"none"}});
            this.setState({style_incorrect3:{display:"none"}});
            this.setState({style_incorrect2:{display:"none"}});
          }
        });
      }
      else{

        if(user.ok === 400){
          this.setState({style_incorrect4:{display:"none"}});
          this.setState({style_incorrect:{display:"block"}});
          this.setState({style_incorrect3:{display:"none"}});
          this.setState({style_incorrect2:{display:"none"}});

        }
        if(user.ok === 401){
          this.setState({style_incorrect4:{display:"none"}});
          this.setState({style_incorrect2:{display:"block"}});
          this.setState({style_incorrect:{display:"none"}});
          this.setState({style_incorrect3:{display:"none"}});

        }
        if(user.ok === 429){
          this.setState({style_incorrect4:{display:"none"}});
          this.setState({style_incorrect3:{display:"block"}});
          this.setState({style_incorrect:{display:"none"}});
          this.setState({style_incorrect2:{display:"none"}});

        }
      }
      

    })





  }


  login(){
  Auth.authenticate();
  }
  logout(){
  Auth.signout();
  }

  updateState() {
        this.setState({ error_msg: true });
    }


  render() {

      

      return (
        <div id="page_connexion_adm" onClick={(event) => this.handlePage(event)}> 
          <div id="design1">
            <div id="decor_adm">
              <div><img id="logo_cfi" src={logo} alt="logo de CFI"/></div>
              <img id="logo_proviso_adm" src={logo_proviso} alt="logo de Proviso"/>
              <p id="texte_accueil">Admin CFI</p>
            </div>
          </div>
          <div id="connexion">
            <div id="formulaire">
              <div id="titre">
                <h3>Identifiant</h3>
              </div>
              <form className="form_connexion" onSubmit={this.onSubmit}>
                <input
                  id="form_email"
                  type="email"
                  placeholder="Email"
                  name="email"
                  value={this.state.email}
                  onChange={this.onChange}
                  />
                <br/>
                <input
                  id="form_mdp"
                  type="password"
                  placeholder="Mot de passe"
                  name="password"
                  value={this.state.password}
                  onChange={this.onChange}
                />
                <br/>
                <div id="this_button">
                   <button id="form_button" type="submit" onClick={(event) => this.redirectToHome}>SE CONNECTER</button>
                  <p id="incorrect" style={this.state.style_incorrect}>Email incorrect</p>
                  <p id="incorrect2" style={this.state.style_incorrect2}>Mot de passe incorrect</p>
                  <p id="incorrect3" style={this.state.style_incorrect3}>Trop de tentatives, veuillez réessayer dans 5 minutes</p>
                  <p id="incorrect4" style={this.state.style_incorrect4}>Compte non admin</p>
                </div>
              </form>
            </div>
          </div>
        </div>


        );
    
    
    
    }
  }
const Log_adm = connect(
  null,
  mapDispatchToProps
)(Connected_log_adm);

export default Log_adm;
