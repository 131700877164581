import axios from 'axios';

export const prestaTele = (link) => {
	let linkTestCors = "https://cfi-oxy.easyvista.com/api/v1/50004/documents/50004_3f48d044e4db0e420c0aca5f77a13af3c4adc83a48d44558243988ed2da98abf";
    // nomDoc = nomDoc.substring(102, nomDoc.length);
    // nomDoc = nomDoc.replace(/%/ig, ' ');
    // nomDoc = nomDoc.replace(/2B/ig, ' ');
    // nomDoc = nomDoc.replace(/25C3/ig, ' ');
    // nomDoc = nomDoc.replace(/25A9/ig, ' ');
	return axios({
         method: 'get',
         url:"http://proviso-api.cfigroupe.com/download",
      	responseType: 'blob'})
	    .then((response) => {
		const url = window.URL.createObjectURL(new Blob([response.data]));
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', 'test_cors.pdf');
		document.body.appendChild(link);
		link.click();
      })
	    .catch(err => {
	      	console.log(err);	      	
	    })
}

export const login = user => {
	return axios({
          method: 'post',
          url: process.env.REACT_APP_API+'/users/login',
          data: {
            email: user.email,
	      	password: user.password
          },
          auth: {
            username: process.env.REACT_APP_ID,
	    	password: process.env.REACT_APP_MDP
          },
        })
	    .then(response => {
	      	localStorage.setItem('usertoken', response.data);
	      
	      return response.data;
	    })
	    .catch(err => {
	      	console.log(err);
	      	if(typeof err.response.status != undefined){
	      		if(err.response.status === 429){
	      			user.ok = 429;
	      	}
	      	if(err.response.status === 400){
	      			user.ok = 400;
	      	}
	      	if(err.response.status === 401){
	      			user.ok = 401;
	      	}
	      	}	
	    })
}

export const update_userf = user => {
	return axios({
          method: 'put',
          url: process.env.REACT_APP_API+'/users/update_userf',
          data: {
    		email: user.email.email,
    		password: user.password,
          },
          auth: {
            username: process.env.REACT_APP_ID,
	    	password: process.env.REACT_APP_MDP
          },
        })
	    .then(response => {  
	      return response.data;
	    })
	    .catch(err => {
	      	console.log(err);	
	    })
}

export const update_user = user => {
	
	return axios({
          method: 'put',
          url: process.env.REACT_APP_API+'/users/update_user/'+user.id,
          data: {
            first_name: user.first_name,
    		last_name: user.last_name,
    		email: user.email,
    		password: user.password,
    		groupe: user.groupe,
    		num_client: user.num_client,
    		tel: user.tel,
    		etps: user.etps,
    		site_web: user.site_web,
   			adr: user.adr,
    		postal: user.postal,
    		ville: user.ville,
    		pays: user.pays,
    		pays_num: user.pays_num,
			contract_date: user.contract_date,
			user_detail: user.user_detail
          },
          auth: {
            username: process.env.REACT_APP_ID,
	    	password: process.env.REACT_APP_MDP
          },
        })
	    .then(response => {  
	      return response.data;
	    })
	    .catch(err => {
	      	console.log(err);	
	    })
}


export const update_logo = dataImg => {
	var bodyFormData = new FormData();
	bodyFormData.set('etps', dataImg.etps);
	bodyFormData.append('file', dataImg.selectedFile); 
	return axios({
          method: 'put',
          url: process.env.REACT_APP_API+'/users/update_logo/'+dataImg.id,
          data:bodyFormData,
          headers: {'Content-Type': 'multipart/form-data' },
          auth: {
            username: process.env.REACT_APP_ID,
	    	password: process.env.REACT_APP_MDP
          },
        })
	    .then(response => {  
	      return response.data;
	    })
	    .catch(err => {
	      	console.log(err);
	    })
}

export const deleteuser = user => {
	
	return axios({
          method: 'delete',
          url: process.env.REACT_APP_API+'/users/delete_user/'+user,
          auth: {
            username: process.env.REACT_APP_ID,
	    	password: process.env.REACT_APP_MDP
          },
        })
	    .then(response => {  
	      return response.data;
	    })
	    .catch(err => {
	      	console.log(err);	
	    })
}

export const deletelogo = user => {
	
	return axios({
          method: 'delete',
          url: process.env.REACT_APP_API+'/users/delete_logo/'+user,
          auth: {
            username: process.env.REACT_APP_ID,
	    	password: process.env.REACT_APP_MDP
          },
        })
	    .then(response => {  
	      return response.data;
	    })
	    .catch(err => {
	      	console.log(err);	
	    })
}

export const register = user => {
	
	return axios({
          method: 'post',
          url: process.env.REACT_APP_API+'/users/register/',
          data: {
            first_name: user.first_name,
    		last_name: user.last_name,
    		email: user.email,
    		password: user.password,
    		groupe: user.groupe,
    		num_client: user.num_client,
    		tel: user.tel,
    		etps: user.etps,
    		site_web: user.site_web,
   			adr: user.adr,
    		postal: user.postal,
    		ville: user.ville,
    		pays: user.pays,
    		pays_num: user.pays_num,
			contract_date: user.contract_date,
			user_detail: user.user_detail
          },
          auth: {
            username: process.env.REACT_APP_ID,
	    	password: process.env.REACT_APP_MDP
          },
        })
	    .then(response => {  
	      return response.data;
	    })
	    .catch(err => {
	      	console.log(err);	
	    })
}

export const sendImg = dataImg => {
	var bodyFormData = new FormData();
	bodyFormData.set('etps', dataImg.etps);
	bodyFormData.append('file', dataImg.selectedFile); 
	return axios({
          method: 'post',
          url: process.env.REACT_APP_API+'/users/create_img/',
          data:bodyFormData,
          headers: {'Content-Type': 'multipart/form-data' },
          auth: {
            username: process.env.REACT_APP_ID,
	    	password: process.env.REACT_APP_MDP
          },
        })
	    .then(response => {  
	      return response.data;
	    })
	    .catch(err => {
	      	console.log(err);
	    })
}

export const profile = () => {
	return axios({
          method: 'get',
          url: process.env.REACT_APP_API+'/users/profile',
          headers:{
                Authorization : localStorage.getItem('usertoken')       
         } })
	    .then(response => {      
	      return response.data;
	    })
	    .catch(err => {
	      	console.log(err);   	
	    })
}

export const presta = () => {	
	return axios({
         method: 'get',
         url:process.env.REACT_APP_API+'/users/presta',
         auth: {
            username: process.env.REACT_APP_ID,
	    	password: process.env.REACT_APP_MDP
          }})
	    .then(response => {
	      
	      return response.data;
	    })
	    .catch(err => {
	      	console.log(err);
	      	
	    })
}

export const findallusers = () => {
	
	return axios({
         method: 'get',
         url:process.env.REACT_APP_API+'/users/findallusers',
         auth: {
            username: process.env.REACT_APP_ID,
	    	password: process.env.REACT_APP_MDP
          }})
	    .then(response => {
	      
	      return response.data;
	    })
	    .catch(err => {
	      	console.log(err);
	      	
	    })


}

export const imgList = () => {
	
	return axios({
         method: 'get',
         url:process.env.REACT_APP_API+'/users/img',
         auth: {
            username: process.env.REACT_APP_ID,
	    	password: process.env.REACT_APP_MDP
          }})
	    .then(response => {
	      return response.data;
	    })
	    .catch(err => {
	      	console.log(err);
	      	
	    })


}


export const imgGet = name => {
	
	return axios({
         method: 'get',
         url:process.env.REACT_APP_API+'/users/display/'+name,
         auth: {
            username: process.env.REACT_APP_ID,
	    	password: process.env.REACT_APP_MDP
          }})
	    .then(response => {
	      return response.data;
	    })
	    .catch(err => {
	      	console.log(err);
	      	
	    })


}


export const commande = num => {

	var final = num.replaceAll("/",",");
	
	return axios({
          method: 'get',
          url: process.env.REACT_APP_API+'/users/commande/client/'+final,
          auth: {
            username: process.env.REACT_APP_ID,
	    	password: process.env.REACT_APP_MDP
          },
        })
	    .then(response => {
	      
	      return response.data;
	    })
	    .catch(err => {
	      	console.log(err);
	      	
	    })


}

export const detail = num => {
	
	return axios({
          method: 'get',
          url: process.env.REACT_APP_API+'/users/detail/'+num,
          auth: {
            username: process.env.REACT_APP_ID,
	    	password: process.env.REACT_APP_MDP
          },
        })
	    .then(response => {
	      
	      return response.data;
	    })
	    .catch(err => {
	      	console.log(err);
	      	
	    })


}




export const commande_groupe = num => {
	
	return axios({
          method: 'get',
          url: process.env.REACT_APP_API+'/users/commande/groupe/'+num,
          auth: {
            username: process.env.REACT_APP_ID,
	    	password: process.env.REACT_APP_MDP
          },
        })
	    .then(response => {
	      
	      return response.data;
	    })
	    .catch(err => {
	      	console.log(err);
	      	
	    })


}

export const check = num => {
	
	return axios({
          method: 'get',
          url: process.env.REACT_APP_API+'/users/check/'+num,
          auth: {
            username: process.env.REACT_APP_ID,
	    	password: process.env.REACT_APP_MDP
          },
        })
	    .then(response => {
	      
	      return response.data;
	    })
	    .catch(err => {
	      	console.log(err);
	      	
	    })


}

export const mail = num => {
	
	return axios({
          method: 'get',
          url: process.env.REACT_APP_API+'/users/mail/'+num,
          auth: {
            username: process.env.REACT_APP_ID,
	    	password: process.env.REACT_APP_MDP
          },
        })
	    .then(response => {
	      
	      return response.data;
	    })
	    .catch(err => {
	      	console.log(err);
	      	
	    })


}


export const commande_all = () => {
	
	return axios({
          method: 'get',
          url: process.env.REACT_APP_API+'/users/commande/',
          auth: {
            username: process.env.REACT_APP_ID,
	    	password: process.env.REACT_APP_MDP
          },
        })
	    .then(response => {
	      
	      return response.data;
	    })
	    .catch(err => {
	      	console.log(err);
	      	
	    })


}

export const devis_all = () => {
	
	return axios({
          method: 'get',
          url: process.env.REACT_APP_API+'/users/devis/',
          auth: {
            username: process.env.REACT_APP_ID,
	    	password: process.env.REACT_APP_MDP
          },
        })
	    .then(response => {
	      
	      return response.data;
	    })
	    .catch(err => {
	      	console.log(err);
	      	
	    })


}

export const facture_all = () => {
	
	return axios({
          method: 'get',
          url: process.env.REACT_APP_API+'/users/facture/',
          auth: {
            username: process.env.REACT_APP_ID,
	    	password: process.env.REACT_APP_MDP
          },
        })
	    .then(response => {
	      
	      return response.data;
	    })
	    .catch(err => {
	      	console.log(err);
	      	
	    })


}



export const facture = num => {

	var final = num.replaceAll("/",",");
	
	return axios({
          method: 'get',
          url: process.env.REACT_APP_API+'/users/facture/client/'+final,
          auth: {
            username: process.env.REACT_APP_ID,
	    	password: process.env.REACT_APP_MDP
          },
        })
	    .then(response => {
	      
	      return response.data;
	    })
	    .catch(err => {
	      	console.log(err);
	      	
	    })


}

export const facture_groupe = num => {
	
	return axios({
          method: 'get',
          url: process.env.REACT_APP_API+'/users/facture/groupe/'+num,
          auth: {
            username: process.env.REACT_APP_ID,
	    	password: process.env.REACT_APP_MDP
          },
        })
	    .then(response => {
	      
	      return response.data;
	    })
	    .catch(err => {
	      	console.log(err);
	      	
	    })


}

export const devis = num => {

	var final = num.replaceAll("/",",");
	
	return axios({
          method: 'get',
          url: process.env.REACT_APP_API+'/users/devis/client/'+final,
          auth: {
            username: process.env.REACT_APP_ID,
	    	password: process.env.REACT_APP_MDP
          },
        })
	    .then(response => {
	      
	      return response.data;
	    })
	    .catch(err => {
	      	console.log(err);
	      	
	    })


}

export const devis_groupe = num => {
	
	return axios({
          method: 'get',
          url: process.env.REACT_APP_API+'/users/devis/groupe/'+num,
          auth: {
            username: process.env.REACT_APP_ID,
	    	password: process.env.REACT_APP_MDP
          },
        })
	    .then(response => {
	      
	      return response.data;
	    })
	    .catch(err => {
	      	console.log(err);
	      	
	    })

}


export const findoneuser = id => {
	
	return axios({
          method: 'get',
          url: process.env.REACT_APP_API+'/users/findoneuser/'+id,
          auth: {
            username: process.env.REACT_APP_ID,
	    	password: process.env.REACT_APP_MDP
          },
        })
	    .then(response => {
	      
	      return response.data;
	    })
	    .catch(err => {
	      	console.log(err);
	      	
	    })


}

export const findalllogos = () => {
	
	return axios({
         method: 'get',
         url:process.env.REACT_APP_API+'/users/findalllogos',
         auth: {
            username: process.env.REACT_APP_ID,
	    	password: process.env.REACT_APP_MDP
          }})
	    .then(response => {
	      
	      return response.data;
	    })
	    .catch(err => {
	      	console.log(err);
	      	
	    })


}


export const findonelogo = id => {
	
	return axios({
          method: 'get',
          url: process.env.REACT_APP_API+'/users/findonelogo/'+id,
          auth: {
            username: process.env.REACT_APP_ID,
	    	password: process.env.REACT_APP_MDP
          },
        })
	    .then(response => {
	      
	      return response.data;
	    })
	    .catch(err => {
	      	console.log(err);
	      	
	    })


}